import React from "react";
import EditorSiteForm from "./EditorSiteForm";
import ConsumerPlusEditor from "./ConsumerPlusEditor";
import SiteConstants from "../../constants/Site";
import {
  CONSUMER_PLUSStep0,
  PRODUCERStep0,
  CONSUMERStep0,
} from "./Step0Components/Bundler";

export default function EditorContainer(props) {
  const { userType, siteToEdit } = props;
  if (!siteToEdit) {
    switch (userType) {
      case SiteConstants.type.CONSUMER:
        return <CONSUMERStep0 {...props} />;
      case SiteConstants.type.CONSUMER_PLUS:
        return <CONSUMER_PLUSStep0 {...props} />;
      case SiteConstants.type.PRODUCER:
        return <PRODUCERStep0 {...props} />;
      default:
        return (
          <div>
            <h2>Ooops</h2>
          </div>
        );
    }
  }

  switch (siteToEdit.type) {
    case SiteConstants.type.CONSUMER_PLUS:
      return <ConsumerPlusEditor type={siteToEdit.type} {...props} />;

    default:
      return <EditorSiteForm type={siteToEdit.type} {...props} />;
  }
}
