const fr_captions = {
  captions: {
    costPerMonth: "Répartition de la facture par mois",
    energyBalance:
      "Répartition énergétique mensuelle par tarif de la consommation",
    loadCurve: "Evolution de la consommation",
    dailyProfile: "Regroupement par jour de la semaine",
    histogram: "Répartition des puissances consommées par tranche et par heure",
    capex: "Dépenses d'investissement",
    capexYear: "Dépenses d'investissement pour l'année séléctionnée",
    opex: "Charges d'exploitation",
    opexYear: "Charges d'exploitation à partir de l'année séléctionnée",
    subvention: "Subvention à partir de l'année séléctionnée",
    sellingPrice:
      "Tarif de l'énergie vendue sur le réseau à partir de l'année sélectionnée",
    unitLocation: "Changer d'unité",
    unitLocationDetail:
      "Cliquez ici pour changer l'unité entre €/kWc/ans, €/an et c€/kWh",
    unit: "Unité",
    unitDetail: "Cliquez ici pour changer d'unité",
    van: "Somme des flux de trésorerie",
    tri: "Taux de Rentabilité Interne",
    timeROI: `Le temps de retour actualisé, est l'année à laquelle la somme des gains cumulés devient égale à l'investissement, mais en tenant compte de la valeur de ces flux dans le temps (actualisation).`,
    timeRNA:
      "Le temps de retour est l'année à laquelle la somme des gains cumulés devient égale à l'investissement.",
    selfConsumptionProject: `1 numéro de compteur, 1 producteur, 1 consommateur`,
    fullSellOutProject:
      "Vente de l’énergie produite sans répondre à une consommation",
    consumer_plusProject:
      "Différents compteurs, différents consommateurs. Étudiez vos projets d'autoconsommation collective en optimisant le dimensionnement du PV et le choix des compteurs concernés par l'opération",
    supplierProject: "Project producteur",
    csv: "Choisissez ce format en cas d’absence de PRM pour renseigner la courbe de charge du bâtiment (en watt) au pas de temps variant entre 10 minutes et une heure",
    profile:
      "Un profil type reflète une forme de consommation, c’est-à-dire la façon dont un client moyen consomme son électricité au cours du temps",
    step3ClassicIndication:
      "Renseignez ici les coûts liés à la partie fourniture uniquement (à retrouver sur les factures hiver et été)",
    step3TarifBuilderIndication:
      "Si projet en dehors de la France : optez pour la création d’une structure tarifaire sur-mesure avec les “Tarifs personnalisés” en définissant les tranches horaires par leurs noms et les coûts fixes et variables correspondant à chacune.",
    CSPERate:
      "Tarif intégrant l'ancienne TCCFE, TDCFE et CSPE depuis janvier 2023. Certaines entreprises industrielles ont un taux réduit, dans ce cas pensez à le modifier.",
    applyDefaultRates:
      "Pour configurer votre structure tarifaire par défaut, RDV dans “mon compte > tarifs par défaut",
    manageFolders:
      "Créez ici des dossiers pour classer vos projets par catégories",
    actualizationRate:
      "Permet d'apprécier la valeur des flux futurs (gains et revenus mais aussi dépenses) à la date d'aujourd'hui. Il permet de calculer la valeur actuelle nette à une année donnée ou autrement dit le gain cumulé actualisé à cette année.",
    opexInflation:
      "Taux avec lequel seront impactés les couts d'exploitation ou de location des installations ou les prix des contrats d'achat en PPA.",
    taxRate:
      "Taux appliqué sur les gains nets du consommateur (revenus de vente et économies de facture moins les dépenses).",
    interestRate: "Taux d’intérêt du prêt souscrit pour le projet.",
    debtDuration: "Durée sur laquelle l’emprunt est contracté",
    debtRatio:
      "Représente la part empruntée sur la totalité du cout d'investissement du projet. Si le consommateur emprunte 80 % à la banque et finance les 20% restants en fonds propre, entrez la valeur « 80 ».",
    sellingPriceInflation:
      "Taux avec lequel seront inflatés les revenus de revente de la production locale photovoltaïque, éolienne ou hydrogène.",
    technologies: {
      pv: {
        trackingSystem:
          "Les panneaux PV peuvent être montés sur des systèmes de suivi mono-axial ou bi-axial pour optimiser la captation de la lumière solaire. Un tracker mono-axial, permet aux panneaux de suivre le soleil tout au long de la journée pour minimiser l'angle d'incidence. Un tracker bi-axial oriente les panneaux dans les directions est-ouest et en inclinaison, assurant un alignement optimal avec le soleil en permanence.",
        optimizePeakPower:
          "Cochez le bouton “Optimisation” pour laisser le logiciel calculer la puissance crête optimale ou décochez-le pour forcer la valeur en entrée.",
        AOVS: "Le cas d’usage “Autoconso optimisée” permet de calculer la taille de l’installation PV qui maximise l'autoconsommation et les économies liées au lieu de maximiser la revente du surplus.",
        systemLossOrProductible:
          "Vous pouvez choisir entre Pertes du système (productible calculé en sortie) ou Productible annuel (fixé en entrée).",
        degradRate:
          "S’applique sur le productible PV et l’usure des panneaux d’année en année",
        yieldSTC:
          "Puissance crête du panneau (kW) / Surface du panneau (m2) x100.",
        systemLoss:
          "Pertes du système électrique DC et AC dues au mismatch, câblage, convertisseurs DC, onduleurs et transformateurs. La performance de production des modules photovoltaïques n'est pas incluse.",
        productible:
          "Quantité d'électricité qui sera produite la première année par l'installation, en AC et après toutes pertes, pour un kWc installé.",
        existingInstallationExplication:
          "Si cochée, l'installation sera considérée comme déjà réalisée et la consommation résultant de son exploitation sera considérée comme référence. Les gains et les couts économiques du scénario seront donc calculés sur les autres technologies rajoutées seulement.",
      },
      grid: {
        subscribedPowers:
          "Cochez “Optimisation” pour laisser le logiciel calculer les Puissances souscrites optimales adaptées à la consommation et l’autoproduction future du projet. Décochez-le pour maintenir les puissances souscrites à leurs valeurs actuelles.",
        connectingPv:
          "La puissance maximale en injection prise en compte pour dimensionner les ouvrages de raccordement. Le producteur prend toute disposition pour ne jamais injecter à cette limite",
        prAmt:
          "Le montant à payer pour réaliser les travaux de modification de la puissance de raccordement.",
      },
      battery: {
        arbitrage:
          "L'arbitrage économique permet de décharger la batterie aux moments où le prix d'achat de l'énergie réseau est élevé.",
        soc: "Etat de charge",
        roundTripYield:
          "Rendement DC / DC de la batterie (avant conversion). Par ex. : pour 1 kWh chargé, 0,92 kWh est capable d’être déchargé.",
      },
      IRVE: {
        usageProbability:
          "La probabilité d’utilisation des bornes (en %) peut être différenciée par période. Ces périodes sont spécifiées par les mois, les jours de la semaine et les plages horaires (début et fin) qui les composent. Pour créer une nouvelle période avec une probabilité d’utilisation différente, cliquez sur “Ajouter une utilisation”",
        addStationType:
          "Vous pouvez créer plusieurs types de bornes différentes. Pour chacune, spécifiez le nombre, la puissance et les probabilités d’utilisation.",
      },
      wt: {
        turbineYield:
          "Le rendement moyen dépend de l’énergie du vent disponible à un moment T (Event) et de l’énergie électrique produite par l’éolienne (Elec). Rendement moyen global = Elec / Event",
      },
      vs: {
        arbitrage:
          "Désactiver l’arbitrage économique forcera la batterie à se décharger dès que les EnR ne produiront plus d’énergie.",
        vsFixedPrice: "Coût de l’abonnement au stockage virtuel",
        vsSuppliedEnergyRate:
          "Coût pour faire revenir l’énergie stockée sur le réseau (du stockage jusqu’au projet traité).",
        vsPvRate: "Prix payé pour le stockage virtuel par kWc installé.",
      },
    },
    graphs: {
      loadCurve:
        "Vous pouvez zoomer sur une période en particulier (clic gauche + glisser de haut en bas sur la zone souhaitée)",
      dailyProfile:
        "Vous pouvez désélectionner un jour dans la légende pour qu’il ne s’affiche plus parmi les courbes",
      histogram:
        "Ce graphique affiche la répartition des puissances soutirées au réseau sur l’année",
      costPerMonth: "",
      energyBalance: "",
    },
    scenarioObjectives: {
      yourObjectives:
        "Si besoin, configurez ici les seuils minimaux à atteindre en Alimentation secours, Autoproduction et Réduction de CO2. Par défaut, ces seuils minimaux sont fixés à 0.",
      "Alimentation secours": "Batterie, générateur, pile à combustible",
    },
    navigation: {
      toScenarios:
        "Veuillez analyser les données de votre projet pour continuer vers les scénarios",
      disabledConsent:
        "Projet bloqué pour l'absence du consentement. Veuillez l'ajouter à l'étape 2",
      toResults:
        "Si vous aviez déjà lancé le calcul de votre scénario précédemment, pas besoin de regénérer un calcul, allez directement vers les résultats.",
      exportOptimParams:
        "Télécharger les hypothèses au format json, à ouvrir via Bloc Notes ou Notepad.",
    },
    results: {
      capex:
        "Coût d’investissement total du projet = somme des CAPEX des différentes technologies.",
      van: "Valeur actuelle nette calculée à la dernière année de simulation, elle mesure la rentabilité des investissements sur toute la durée du projet (par ex. 25 ans).",
      tri: "Le taux de rentabilité interne des fonds propres mesure la rentabilité des seuls capitaux investis par le client en tenant compte de l’emprunt et incluant les intérêts et remboursements dans les flux financiers.",
      triProject:
        "Le taux de rentabilité interne du projet mesure la rentabilité des fonds investis sans prise en compte du financement. C’est le taux d’actualisation des flux financiers qui permet d’égaliser le capex initial sur la durée de vie du projet.",
      timeROI:
        "Année à laquelle la somme des gains cumulés et actualisés (avec un taux d'actualisation qui baisse la valeur de l'argent dans le temps) devient égale à l'investissement.",
      timeRNA:
        "Année à laquelle la somme des gains cumulés devient égale à l'investissement.",
    },
  },
};

const en_captions = {
  captions: {
    costPerMonth: "Detailed monthly bill",
    energyBalance: "Monthly energy breakdown by price range",
    loadCurve: "Evolution of consumption",
    dailyProfile: "Repartition by day of week",
    histogram: "Powers groupped by power range and number of hour",
    capex: "Investment expenditure",
    capexYear: "Investment expenditure for the selected year",
    opex: "Operational expenditure",
    opexYear: "Operational expenditure from the selected year",
    subvention: "Subvention from the selected year",
    sellingPrice: "Price of energy sold on the network from the selected year",
    unitLocation: "Change unit",
    unitLocationDetail:
      "Click here to change the unit between kWp/year and kWh",
    van: "Sum of all cashflows",
    tri: "Internal rate return",
    timeROI: `The year in which earnings amount to the same value as the initial investment, taking the actual value of earnings in time (actualization)`,
    timeRNA:
      "The year in which earnings amount to the same value as the initial investment",
    selfConsumptionProject: `1 counter, 1 supplier, 1 consumer`,
    fullSellOutProject: "Selling all energy production without consumption",
    consumer_plusProject:
      "Several counters, several consumers. Study your collective consumption projects while optimizing the size of the solar panels installation and choosing which counter will be impacted",
    supplierProject: "Supplier project",

    csv: "Choose this format if you don't have an electricity meter number, with values in watt and a step between 10min and 1 hour",
    profile:
      "A profile replicates the average consumption behavior of a typology of consumer over time",
    step3ClassicIndication: "Indicate here the supply costs",
    step3TarifBuilderIndication:
      "For a project outside of France, build your own price structure by defining for each range fixed and variables costs.",
    CSPERate: "Some firms may have a lower rate",
    applyDefaultRates:
      "To configure a default rate structure, go to My account => Default Rates",
    manageFolders: "Create folders to organize your projects in categories",
    actualizationRate:
      "Allows to calculate the value of future revenues and costs compared ot their current value",
    opexInflation:
      "The rate of inflation for OPEX, leasing costs and PPA costs",
    taxRate:
      "Rate applied to the ocnsumer's net earnings (sales and savings minus expenditures).",
    debtRatio:
      "The share of the total investment that will be borrowed. If the consumer provides 20% of the investment and borrows 80%, then '80%' is the debt ratio",
    interestRate: "The interest rate of the loan to financing the project",
    debtDuration: "The number of years over which the loan is applied",
    sellingPriceInflation:
      "The rate to which solar panels, windturbine and hydrogen local production sales will be inflated.",

    technologies: {
      pv: {
        trackingSystem:
          "PV panels can be mounted on single-axis or dual-axis tracking systems to optimize solar radiation capture. A single-axis tracker, allows panels to follow the sun throughout the day to minimize the angle of incidence. A dual-axis tracker adjusts the panels in both the east-west direction and in tilt, ensuring they are always optimally aligned with the sun.",
        optimizePeakPower:
          "Tick “Optimization” to let the program compute the optimal peak power, or untick the box to apply the chosen value.",
        AOVS: "Self consuming and selling surplus allows for an installation maximizing savings by self-consumption instead of surplus selling.",
        systemLossOrProductible:
          "You can choose between System Loss (productible computed after simulation) or Productible (fixed before simulation).",
        degradRate:
          "Applied on the productible energy, it represents the panels degradation over the years",
        yieldSTC: "Panel peak power (kW) / Panel area (m2) x100.",
        systemLoss:
          "AC DC system losses due to mismatch, wiring, DC converters, inverters and transformers. Solar panels production efficiency is not taken into account.",
        productible:
          "The amount of electricy that will be produced during the first year of operation, in AC after all losses, for one kWp installed.",
        existingInstallationExplication:
          "When marked as checked, the PV system will be treated as if it's already in place, and the energy consumption it generates will be used as the baseline. Consequently, the benefits and financial implications of this scenario will be calculated exclusively for the additional technologies introduced.",
      },
      grid: {
        subscribedPowers:
          "Tick “Optimization” to let the program compute the optimal subscribed powers for the consumption and self-production of the project. Leave it unticked to keep the current values.",
        connectingPv:
          "The maximum allowable injection power considered in the dimensioning of connection infrastructure. The producer implements all requisite measures to prevent the injection of a power exceeding this threshold into the network.",
        prAmt:
          "The payement required for the overage resulting from a modification of the connection power",
      },
      battery: {
        arbitrage:
          "Arbitrage allows the battery to discharge when buying from the grid network is expensive.",
        soc: "State of Charge",
        roundTripYield:
          "DC / DC battery yield (before conversion). i.e : for 1kWh loaded, 0.92 kWh can be discharged.",
      },
      IRVE: {
        usageProbability:
          "Charing stations probabilities of usage (as %) can be differentiated by time periods. They are characterized by months, days, and start time / end time. To create a new time period with a different probability of usage, click 'Add a use case'",
        addStationType:
          "You can create several types of stations. For each, specify a number of units, power, and usage probability",
      },
      wt: {
        turbineYield:
          "Yield depends on the available wind energy at a certain time (Event) and the electrical energy produced by the turbine (Elec). The global average yield = Elec / Event",
      },
      vs: {
        arbitrage:
          "Disabling arbitrage will force the battery to discharge as soon as renewable energy sources cease to produce energy.",
        vsFixedPrice: "The cost of subscribing to virtual storage",
        vsSuppliedEnergyRate:
          "The cost of bringing back the energy from storage.",
        vsPvRate: "Price per kWp.",
      },
    },
    graphs: {
      loadCurve:
        "Draw a rectangle on the graph to zoom in. Double click to reset.",
      dailyProfile: "Click a day in the legend to hide it from the graphs",
      histogram:
        "This graph shows how power is distributed in the year's total consumption",
      costPerMonth: "",
      energyBalance: "",
    },
    scenarioObjectives: {
      yourObjectives:
        "Configure here the minimum threshold to reach for backup storage, Self-production and CO2 emissions reduction.",
      "Alimentation secours": "battery, generator, fuel cell",
    },
    navigation: {
      toScenarios: "Analyze your project data to go to the scenarios",
      disabledConsent:
        "Project blocked for lack of proof of consent. Please add it in step 2",
      toResults:
        "If you have previously launched a simulation, click here to see the results directly.",
      exportOptimParams:
        "Click here to download a summary of your scenario settings",
    },
    results: {
      capex:
        "Total capital expenditures = sum of all CAPEX of all technologies involved.",
      van: "The Net Present Value is computed on the last year of the simulation, it measures the profitability of investments throughout the project lifespan.",
      tri: "The internal rate of return on equity measures the profitability of only the capital invested by the client, taking into account the loan and including interest and repayments in the financial flows.",
      triProject:
        "The internal rate of return of the project measures the profitability of the funds invested without taking into account financing. It is the discount rate of financial flows which makes it possible to equalize the initial capex over the lifespan of the project.",
      timeROI:
        "The year from which the sum of all actualized cashflows becomes equal to the investment.",
      timeRNA:
        "The year from which the sum of all cashflows becomes equal to the investment",
    },
  },
};

export { fr_captions, en_captions };
