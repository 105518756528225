import { BehaviorSubject } from "rxjs";
import { GRID_REFERENCES } from "../../components/shared/ReactGridLayout/constants";
import User from "../../services/User";

export const ACTIONS = {
  update: "update",
  reset: "reset",
};

const SORT = {
  HYPHOTESIS: "Hypothèse",
  NAME: "name",
  VAN: "van",
  CCF: "ccf",
  SCOPE: "scope",
  CAPEX: "capex",
  TRI: "tri",
  TRIPROJECT: "triProjet",
  TIMEROI: "timeROI",
  TIMERNA: "timeRNA",
  UNITCOST: "unitCost",
  PVPEAK: "pvPeakPower",
};

let defaultValues = {
  GENERAL: [],
  SCENARIO: [],
  ANALYSIS_INDIV: [],
  ANALYSIS_COLL: [],
};

const defaultGridLayout$ = new BehaviorSubject(defaultValues);
const gridLayout$ = new BehaviorSubject(null);
const columnsReset$ = new BehaviorSubject(true);

export const setDefaultGridLayout = (type, payload) => {
  switch (type) {
    case GRID_REFERENCES.OPTIM_GENERAL_CONSUMER:
      defaultValues["GENERAL"] = payload;
      break;
    case GRID_REFERENCES.OPTIM_SCENARIO_CONSUMER:
      defaultValues["SCENARIO"] = payload;
      break;
    case GRID_REFERENCES.ANALYSIS_INDIVIDUAL:
      defaultValues["ANALYSIS_INDIV"] = payload;
      break;
    case GRID_REFERENCES.ANALYSIS_COLLECTIVE:
      defaultValues["ANALYSIS_COLL"] = payload;
      break;
    default:
      console.log("This type doesn't match with existing default grid values");
      break;
  }
  defaultGridLayout$.next(defaultValues);
};

export const getDefaultGridLayout = (type) => {
  switch (type) {
    case GRID_REFERENCES.OPTIM_GENERAL_CONSUMER:
      return defaultValues["GENERAL"];
    case GRID_REFERENCES.OPTIM_SCENARIO_CONSUMER:
      return defaultValues["SCENARIO"];
    case GRID_REFERENCES.ANALYSIS_INDIVIDUAL:
      return defaultValues["ANALYSIS_INDIV"];
    case GRID_REFERENCES.ANALYSIS_COLLECTIVE:
      return defaultValues["ANALYSIS_COLL"];
    default:
      console.log("This type doesn't match with default grid values");
      break;
  }
};

export const updateGridLayout = (uuid, layout) => {
  gridLayout$.next({ type: ACTIONS.update, uuid, layout });
};

export const resetGridLayout = async (gridReference) => {
  const userId = JSON.parse(localStorage.getItem("userData")).uid;

  const defaultSynthesisValues = await User.getKey(userId, "synthesis_fi");
  const synthesisFiKeys = Object.values(SORT);
  console.log("DEFAULT", defaultSynthesisValues);
  window.localStorage.removeItem("synthesis-fi");
  window.localStorage.setItem(
    "synthesis-fi",
    JSON.stringify(defaultSynthesisValues || synthesisFiKeys)
  );
  window.localStorage.removeItem(gridReference);
  gridLayout$.next({ type: ACTIONS.reset });

  columnsReset$.next(!columnsReset$.getValue());
};

export default gridLayout$;
