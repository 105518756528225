import React, { useEffect, useContext, useState } from "react";
import LocaleContext from "../../context/LocaleContext";
import { Columns, Button, Loader } from "react-bulma-components/full";
import dayjs from "dayjs";
import loadable from "loadable-components";
import {
  isAllSitesAnalyzable,
  isAllSitesOptimizable,
  isSiteExtractingData,
  isSiteHasError,
  isSiteNeedUpdate,
  // isSiteIsDisabled,
  isSomeSitesExtractingData,
  isSomeSitesHasError,
  isSomeSitesNeedUpdate,
} from "../../helpers/Validators";
import classNames from "classnames";
import SiteService from "../../services/Site";
import ScenarioService from "../../services/Scenario";
import PLANS from "../../constants/Plans";
import SiteActionsContainer from "./SiteActionsContainer";
import { FaList, FaPollH, FaEdit, FaExclamationTriangle } from "react-icons/fa";
import { SiGooglemaps, SiGoogleanalytics } from "react-icons/si";
import { isSiteCompletelyConfigured } from "../../helpers/Validators";
import { CustomButton, StyledLoader } from "../CustomButton";
import { CustomButtonSecondary } from "../CustomButtonSecondary";
import { BsFillTrash3Fill } from "react-icons/bs";
import { ShowConsentFileStatus } from "./ConsentFileWarning";
import { extractEnedisErrorCode, formatErrorStatus } from "../../utils/Utils";

const ReportResult = loadable(() => import("../../components/AnalyzeResult"));

const ErrorMessages = ({ sites, forceUpdate, collectiveSite }) => {
  const { Translation } = useContext(LocaleContext);
  return sites
    .filter((site) => isSiteHasError(site))
    .map(({ name, automaticUpdateError, reportURL, enedisNumber }, index) => {
      if (!automaticUpdateError && !reportURL)
        return (
          <span key={index} style={{ display: "block" }}>
            {name} - <Translation>{(t) => t("faultyProject")}</Translation>
          </span>
        );

      return (
        <span key={index} style={{ display: "block" }}>
          {name} -{" "}
          {formatErrorStatus(extractEnedisErrorCode(automaticUpdateError))}
          {isSiteNeedUpdate({
            enedisNumber,
            automaticUpdateError,
            reportURL,
          }) && (
            <>
              &nbsp;
              <Translation>{(t) => t("clicking")}</Translation>
              <a
                href="#"
                onClick={() => forceUpdate(collectiveSite)}
                style={{
                  color: "red",
                  fontWeight: "bold",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                <Translation>{(t) => t("refresh")}</Translation>
              </a>
            </>
          )}
        </span>
      );
    });
};
const EditorSiteForm = loadable(() =>
  import("../../components/Site/EditorSiteForm")
);

export const SiteListItem = ({
  site,
  handleShowEditSite,
  handleDeletesubSite,
  isCollectiveSiteEditorMode = false,
  fromEditor = false,
}) => {
  const { name, enedisNumber, reportURL } = site;
  const { Translation, i18n } = useContext(LocaleContext);
  console.log({ name: site.name, site });
  return (
    <div
      className={classNames("site", {
        "collective-site-editor": isCollectiveSiteEditorMode,
      })}
    >
      <Columns className="is-centered">
        <Columns.Column
          size={
            // !isCollectiveSiteEditorMode && !fromEditor && isSiteIsDisabled(site)
            //   ? 9
            6
          }
        >
          <div className="site-metadata">
            <div
              className="site-metadata-name is-size-4"
              style={{
                color: name === "Site en paramètrage" ? "red" : "",
              }}
            >
              {name}{" "}
            </div>
            {ShowConsentFileStatus(site)}
          </div>
        </Columns.Column>
        <Columns.Column size={3} style={{ textAlign: "left" }}>
          {site.enedisData ? (
            <div className="site-enedis-number">
              Compteur n° {enedisNumber || ""}
            </div>
          ) : site.profile ? (
            <div className="site-enedis-number">Profil type</div>
          ) : (
            <div className="site-enedis-number">Fichier .csv</div>
          )}
        </Columns.Column>
        <Columns.Column
          size={
            // !isCollectiveSiteEditorMode && !fromEditor && isSiteIsDisabled(site)
            //   ? 0:
            3
          }
        >
          <div
            className="site-report-url"
            style={{
              marginRight: "10%",
            }}
          >
            {isCollectiveSiteEditorMode && (
              <>
                <CustomButton
                  icon={<FaEdit />}
                  padding="2.5% 5%"
                  onClick={() => handleShowEditSite(site)}
                  disabled={isSiteExtractingData(site)}
                >
                  <Translation>{(t) => t("settings")}</Translation>
                </CustomButton>
              </>
            )}
            {fromEditor && (
              <CustomButtonSecondary
                padding="2.5% 1.5%"
                onClick={() => handleDeletesubSite(site)}
                icon={<BsFillTrash3Fill />}
                hoverBackgroundColor="red"
                hoverColor="white"
                // disabled={isSiteIsDisabled(site)}
              >
                {/* {isSiteIsDisabled(site) ? (
                  <CustomHoverPopUp
                    content={i18n.t(`captions.navigation.disabledConsent`)}
                  > */}
                <Translation>{(t) => t("delete")}</Translation>
                {/* </CustomHoverPopUp>
                ) : (
                  <Translation>{(t) => t("delete")}</Translation>
                )} */}
              </CustomButtonSecondary>
            )}
            {reportURL && (
              // !isSiteIsDisabled(site) &&
              <a
                href={reportURL}
                target="_blank"
                rel="noopener noreferrer"
                className="download-consoData"
              >
                <Translation>{(t) => t("downloadCurrentHistory")}</Translation>
                <i className="fa fa-download has-margin-left__Small"></i>
              </a>
            )}
            {isSiteExtractingData(site) && (
              <div className="site-actions has-text-right">
                <p
                  // style={{ color: "#3273dc" }}
                  className="load-message"
                >
                  <Loader className="fetching" />
                  <Translation>{(t) => t("extractingData")}</Translation>
                </p>
              </div>
            )}
          </div>
        </Columns.Column>
      </Columns>
    </div>
  );
};

const CollectiveSiteCollapse = (props) => {
  const { i } = props;
  const [expanded, setExpanded] = useState(false);
  const { Translation } = useContext(LocaleContext);
  const deletesubSiteFromDb = async (subSite) => {
    props.updateCollectiveSiteListOfSites(props.collectiveSite.id, subSite);
    await SiteService.deleteSubSite(subSite);
    await ScenarioService.updateScenariosAfterSiteDeletion(subSite);
  };
  return (
    <div className="collective-site-collapse" style={{ zIndex: 100 - i }}>
      <div className="collapse-container">
        <div
          className="collective-site-collapse-toggler"
          onClick={() => setExpanded(!expanded)}
        >
          <i className={`fa fa-caret-${expanded ? "down" : "right"}`} />
          &nbsp;<Translation>{(t) => t("seeListOfCounters")}</Translation>
        </div>
        {expanded && (
          <div className="collective-site-collapse-body">
            {props.collectiveSite.sites.map((site, i) => (
              <SiteListItem
                key={i}
                site={site}
                handleDeletesubSite={deletesubSiteFromDb}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const CollectiveSiteListItem = (props) => {
  const { selectedSites, handleCheckboxClick } = props;

  const { Translation, i18n } = useContext(LocaleContext);
  const [collectiveSite, setCollectiveSite] = useState(props.collectiveSite);
  const [i, setI] = useState(props.i);

  const [allSitesAnalyzable, setAllSitesAnalyzable] = useState(
    isAllSitesAnalyzable(props.collectiveSite.sites)
  );
  const [allSitesOptimizable, setAllSitesOptimizable] = useState(
    isAllSitesOptimizable(props.collectiveSite.sites)
  );
  const [someSitesExtractingData, setSomeSitesExtractingData] = useState(
    isSomeSitesExtractingData(props.collectiveSite.sites)
  );
  const [someSitesHasError, setSomeSitesHasError] = useState(
    isSomeSitesHasError(props.collectiveSite.sites)
  );
  const [someSitesNeedUpdate, setSomeSitesNeedUpdate] = useState(
    isSomeSitesNeedUpdate(props.collectiveSite.sites)
  );
  const { handleDeleteSite, forceUpdate, folders, userLicense } = props;

  const handleButtonClick = (e) => props.handleClick(e, props.collectiveSite);

  useEffect(() => {
    const { collectiveSite } = props;
    setCollectiveSite(collectiveSite);
    setI(props.i);
    setAllSitesAnalyzable(isAllSitesAnalyzable(collectiveSite.sites));
    setAllSitesOptimizable(isAllSitesOptimizable(collectiveSite.sites));
    setSomeSitesExtractingData(isSomeSitesExtractingData(collectiveSite.sites));
    setSomeSitesHasError(isSomeSitesHasError(collectiveSite.sites));
    setSomeSitesNeedUpdate(isSomeSitesNeedUpdate(collectiveSite.sites));

    props.addConsentKeys(collectiveSite.sites);
    props.checkConsent(collectiveSite.sites);
  }, [props.collectiveSite, props.i]);

  return (
    <>
      <tr>
        <td style={{ verticalAlign: "middle", margin: "auto" }}>
          <input
            checked={!!selectedSites.find((s) => s.id === collectiveSite.id)}
            onChange={() => handleCheckboxClick(collectiveSite)}
            type="checkbox"
            style={{ width: "20px", height: "20px" }}
          ></input>
        </td>
        <td>
          <div className="site-metadata">
            <div
              className="site-metadata name"
              style={{
                color:
                  collectiveSite.name === "Site en paramètrage" ? "red" : "",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                {collectiveSite.name}{" "}
              </div>
            </div>
            {/* {process.env.REACT_APP_ENV !== "production" && (
              <div>{collectiveSite.id}</div>
            )} */}
            <div className="site-metadata address">
              <div className="address-line">
                <span>
                  <SiGooglemaps
                    className="address-icon"
                    style={{ marginRight: "1%" }}
                  />
                  {collectiveSite.address.split(",")[0].trim()}
                  <br />
                  {
                    collectiveSite.address.split(
                      collectiveSite.address.split(", ")[0] + ","
                    )[1]
                  }
                </span>
              </div>
            </div>
            <div
              style={{
                position: "relative",
                display: "flex",
                // zIndex: 9999 - i,
              }}
            >
              <CollectiveSiteCollapse {...props} />
            </div>
            <br />
            <br />
          </div>
        </td>
        <td>
          <div className="site-metadata">
            <div className="site-metadata folder">
              {folders.length > 0 && (
                <>
                  {folders.find((f) => f.id === collectiveSite?.folder)
                    ?.displayName || "Non renseigné"}
                </>
              )}
            </div>
          </div>
        </td>
        <td>
          <div className="site-metadata">
            <div className="site-metadata date">
              {collectiveSite?._lastModified ? (
                <>
                  {dayjs(collectiveSite._lastModified).format("DD/MM/YYYY")}
                  <br />
                  {dayjs(collectiveSite._lastModified).format("HH:mm")}
                </>
              ) : (
                "N/A"
              )}
            </div>
          </div>
        </td>
        <td>
          <div className="site-metadata">
            <div className="site-metadata date">
              {collectiveSite?._createdAt ? (
                <>
                  {dayjs(collectiveSite._createdAt).format("DD/MM/YYYY")}
                  <br />
                  {dayjs(collectiveSite._createdAt).format("HH:mm")}
                </>
              ) : (
                "N/A"
              )}
            </div>
          </div>
        </td>
        <td>
          <div
            className="site-metadata"
            style={{
              marginTop: "20%",
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          ></div>
        </td>
        <td>
          <div className="container-button">
            <div className="update-button-container">
              {collectiveSite.sites.length > 0 && someSitesExtractingData && (
                <div className="site-actions ">
                  <p
                    style={{ color: "#3273dc" }}
                    className="site-actions t content"
                  >
                    {/* <Loader className="fetching" />{" "} */}
                    <Translation>{(t) => t("extractingData")}</Translation>
                  </p>
                </div>
              )}

              {collectiveSite.sites.length > 0 &&
                !someSitesExtractingData &&
                someSitesHasError &&
                collectiveSite.sites.length > 0 && (
                  <div className="error-message">
                    <span>
                      <ErrorMessages
                        sites={collectiveSite.sites}
                        forceUpdate={forceUpdate}
                        collectiveSite={collectiveSite}
                      />
                    </span>
                  </div>
                )}
            </div>
            <SiteActionsContainer>
              <Button
                onClick={handleButtonClick}
                name="analyze"
                disabled={
                  !collectiveSite.sites.length > 0 || !allSitesAnalyzable
                  // || hasDisabledSite(collectiveSite.sites)
                }
                className="analyze"
              >
                <SiGoogleanalytics />
                <span style={{ marginLeft: "13%" }}></span>
                {/* {hasDisabledSite(collectiveSite.sites) ? (
                  <CustomHoverPopUp
                    content={i18n.t(`captions.navigation.disabledConsent`)}
                  > */}
                {i18n.t("analyze")}
                {/* </CustomHoverPopUp>
                ) : (
                  i18n.t("analyze")
                )} */}
              </Button>

              <Button
                className="scenarios"
                onClick={handleButtonClick}
                disabled={
                  !collectiveSite.sites.length > 0 || !allSitesOptimizable
                  // || hasDisabledSite(collectiveSite.sites)
                }
                onMouseOver={() => {
                  ReportResult.load();
                }}
                name="scenarios"
              >
                <FaList /> <span style={{ marginLeft: "13%" }}></span>
                {/* {hasDisabledSite(collectiveSite.sites) ? (
                  <CustomHoverPopUp
                    content={i18n.t(`captions.navigation.disabledConsent`)}
                  > */}
                {i18n.t("pages.scenarios")}
                {/* </CustomHoverPopUp>
                ) : (
                  i18n.t("pages.scenarios")
                )} */}
              </Button>
              <Button
                className="results"
                onClick={handleButtonClick}
                disabled={
                  !collectiveSite.sites.length > 0 || !allSitesAnalyzable
                  // || hasDisabledSite(collectiveSite.sites)
                }
                name="results"
              >
                <FaPollH style={{ fontSize: "18px" }} />{" "}
                <span style={{ marginLeft: "13%" }}></span>
                {/* {hasDisabledSite(collectiveSite.sites) ? (
                    <CustomHoverPopUp
                      content={i18n.t(`captions.navigation.disabledConsent`)}
                    > */}
                {i18n.t("pages.results")}
                {/* </CustomHoverPopUp>
                  ) : (
                    i18n.t("pages.results")
                  )} */}
              </Button>
              <Button
                onClick={handleButtonClick}
                onMouseOver={() => {
                  EditorSiteForm.load();
                }}
                className="modify"
                name="edit"
                editable={isSiteCompletelyConfigured(collectiveSite)}
              >
                <FaEdit /> <span style={{ marginLeft: "13%" }}></span>
                <Translation>{(t) => t("settings")}</Translation>
              </Button>
              <Button
                onClick={() => handleDeleteSite(collectiveSite.id)}
                className="sup"
                name="delete"
                disabled={
                  userLicense === PLANS().DEMO.toUpperCase() ||
                  userLicense === PLANS().LEADS.name
                  // || hasDisabledSite(collectiveSite.sites)
                }
              >
                <i
                  className={classNames("fa fa-trash", {
                    disabled:
                      userLicense === PLANS().DEMO.toUpperCase() ||
                      userLicense === PLANS().LEADS.name,
                    // ||  hasDisabledSite(collectiveSite.sites),
                  })}
                  title="Supprimer le site"
                ></i>{" "}
                <span style={{ marginLeft: "13%" }}></span>
                {/* {hasDisabledSite(collectiveSite.sites) ? (
                  <CustomHoverPopUp
                    content={i18n.t(`captions.navigation.disabledConsent`)}
                  > */}
                <Translation>{(t) => t("delete")}</Translation>
                {/* </CustomHoverPopUp>
                ) : (
                  <Translation>{(t) => t("delete")}</Translation>
                )} */}
              </Button>
            </SiteActionsContainer>
          </div>
        </td>
      </tr>
      {/* <tr>
        <td colSpan={5}>
          <CollectiveSiteCollapse {...props} />
        </td>
      </tr> */}
    </>
  );
};

export default CollectiveSiteListItem;
