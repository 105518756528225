import React, { useEffect, useState } from "react";
import { Menu } from "react-bulma-components/full";
import Classnames from "classnames";
import { Columns } from "react-bulma-components/full";
import ApiService from "../services/Api";
import GeneralSettings from "../components/CloudFunctionsTester/GeneralSettings"
import Test from "../components/CloudFunctionsTester/Test"
import SendMailToAdmin from "../components/CloudFunctionsTester/SendMailToAdmin"
import SendMailToCustomer from "../components/CloudFunctionsTester/SendMailToCustomer"
import SendMailUpdate from "../components/CloudFunctionsTester/SendMailUpdate"
import DuplicateFile from "../components/CloudFunctionsTester/DuplicateFile";
import GetTimezone from "../components/CloudFunctionsTester/GetTimezone";
import SendMailToAdminSignUp from "../components/CloudFunctionsTester/SendMailToAdminSignUp";
import ValidateUser from "../components/CloudFunctionsTester/ValidateUser";
import "./CloudFunctionsTester.scss"

function AsideMenu(props) {
  const { onClick, current, asideMenuItems } = props;

  return (
    <Menu>
      <Menu.List>
        {asideMenuItems?.map((item, idx) => (
          <li key={`aside-menu-item-${idx}`} title={item?.name}>
            <a
              className={Classnames({
                "is-active": current?.name === item?.name,
              })}
              onClick={() => onClick(item)}
            >
              <i className={`fa ${item?.icon || "fa-paper-plane"}`} /> {item?.name}
            </a>
          </li>
        ))}
      </Menu.List>
    </Menu>
  );
}

const asideMenuItems = [
  {
    name: "General settings",
    icon: "fa-cogs",
  },
  {
    name: "test",
  },
  {
    name: "sendMailToAdmin",
  },
  {
    name: "sendMailToCustomer",
  },
  {
    name: "sendMailUpdate",
  },
  {
    name: "sendMailToAdminSignUp",
  },
  {
    name: "validateUser",
  },
  {
    name: "duplicateFile",
  },
  {
    name: "getTimezone",
  },
]

const CloudFunctionsTester = () => {
  const [rendering, setRendering] = useState(asideMenuItems[0])
  const [token, setToken] = useState()

  useEffect(() => {
    ApiService.getToken()
      .then(token => setToken(token))
      .catch(err => console.error(err))
  }, [])

  const handleClickAsideMenu = (item) => {
    setRendering(item)
  }

  const Body = () => {
    switch (rendering.name) {
      case "General settings": return <GeneralSettings handleTokenUpdate={(token) => setToken(token)} token={token} />
      case "test": return <Test token={token} />
      case "sendMailToAdmin": return <SendMailToAdmin token={token} />
      case "sendMailToCustomer": return <SendMailToCustomer token={token} />
      case "sendMailUpdate": return <SendMailUpdate token={token} />
      case "sendMailToAdminSignUp": return <SendMailToAdminSignUp token={token} />
      case "duplicateFile": return <DuplicateFile token={token} />
      case "validateUser": return <ValidateUser token={token} />
      case "getTimezone": return <GetTimezone token={token} />
      default: return null
    }
  }

  return (
    <div className="container__Columns">
      <Columns className="is-fullheight">
        <Columns.Column
          size={2}
        >
          <div className="cloud-functions-tester-menu">
            <AsideMenu
              onClick={handleClickAsideMenu}
              current={rendering}
              asideMenuItems={asideMenuItems}
            />
          </div>
        </Columns.Column>
        <Columns.Column size={10} className="body-container">
          <Body />
        </Columns.Column>
      </Columns>
    </div>)
}

export default CloudFunctionsTester