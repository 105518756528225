import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import randomString from "randomstring";
import _ from "lodash";
import KEYS from "../../keys";

import { addPdfElement } from "../../store/pdf/store";

const OPTIONS = {
  zoomControl: true,
  fullscreenControl: true,
  mapTypeId: "hybrid",
};

const bootstrapURLKeys = {
  key: KEYS.MAPS,
  libraries: ["drawing", "geometry", "places"].join(","),
};

class MapCreator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      area: 0,
      center: {
        lat: 0,
        lng: 0,
      },
      zoom: 15,
    };
    this.refName = React.createRef();
    this.name = "Map";
  }

  getOptions = () => ({
    zoomControl: true,
    fullscreenControl: true,
    mapTypeId: "hybrid",
  });

  shouldComponentUpdate = (nextProps, nextState) => {
    if (!_.isEqual(nextState.center, this.state.center)) return false;
    if (this.props.coordinates)
      if (_.isEqual(nextProps.coordinates, this.props.coordinates))
        return false;
    return true;
  };

  showMap = (map, maps) => {
    const { isDraw, coordinates, path, isInResults } = this.props;
    let area = 0;
    // if polygons were previously drawn
    if (isInResults) {
      addPdfElement(this.name, {
        center: { lat: coordinates[0], lng: coordinates[1] },
        marker: { lat: coordinates[0], lng: coordinates[1] },
        zoom: 18,
        paths: path.map((p) => p.content),
      });
    }

    if (path) {
      path.forEach((p) => {
        let surface = new maps.Polygon({});

        surface.setPath(p.content);
        surface.setOptions({
          fillColor: "orange",
          strokeColor: "orange",
          clickable: true,
        });

        area = maps.geometry.spherical.computeArea(surface.getPath());
        surface.addListener("rightclick", (e) => {
          this.props.triggerCompute(-area, p.content);
          surface.setMap(null);
        });
        surface.addListener("click", (e) => {
          this.props.triggerCompute(-area, p.content);
          surface.setMap(null);
        });

        surface.setMap(map);
      });
    }
    // to draw new polygons
    if (isDraw) {
      if (!maps.drawing) return;
      let draw = new maps.drawing.DrawingManager({
        drawingControlOptions: {
          drawingModes: ["polygon"],
        },
        drawingMode: "polygon",

        polygonOptions: {
          fillColor: "orange",
          strokeColor: "orange",
          clickable: true,
        },
      });
      let marker = new maps.Marker({
        animation: maps.Animation.DROP,
        clickable: false,
        position: { lat: coordinates[0], lng: coordinates[1] },
        visble: true,
        map,
      });
      map.setTilt(0);
      marker.setMap(map);
      maps.event.addListener(draw, "polygoncomplete", (polygon) => {
        draw.setDrawingMode(null);
        area = maps.geometry.spherical.computeArea(polygon.getPath());
        let path = polygon.getPath();

        path = path.getArray().map((e) => {
          return { lat: e.lat(), lng: e.lng() };
        });
        polygon.addListener("rightclick", (e) => {
          let areaToCompute = maps.geometry.spherical.computeArea(
            polygon.getPath()
          );
          this.props.triggerCompute(-areaToCompute, path);
          polygon.setMap(null);
        });
        polygon.addListener("click", (e) => {
          let areaToCompute = maps.geometry.spherical.computeArea(
            polygon.getPath()
          );

          this.props.triggerCompute(-areaToCompute, path);
          polygon.setMap(null);
        });
        this.props.triggerCompute(area, path);

        draw.setDrawingMode("polygon");
      });

      draw.setMap(map);
    } else {
      let marker = new maps.Marker({
        animation: maps.Animation.DROP,
        clickable: false,
        position: { lat: coordinates[0], lng: coordinates[1] },
        visble: true,
        map,
        draggable: true,
      });

      map.setCenter({ lat: coordinates[0], lng: coordinates[1] });
      map.setZoom(18);
      map.setTilt(0);
      marker.addListener("mouseup", (e) => {
        let latLng = marker.getPosition();
        map.setCenter({ lat: latLng.lat(), lng: latLng.lng() });
        this.props.getCodeFromMarker({ lat: latLng.lat(), lng: latLng.lng() });
      });
      marker.setMap(map);
    }
    // if (!isDraw) {
    //   map.addListener("tilesloaded", (e) => {
    //     this.getURL();
    //   });
    //   // this.getURL();
    // }
  };

  // componentDidMount = () => {
  //   var head = document.getElementsByTagName("head")[0];

  //   // Save the original method
  //   var insertBefore = head.insertBefore;

  //   // Replace it!
  //   head.insertBefore = function (newElement, referenceElement) {
  //     if (
  //       newElement.href &&
  //       newElement.href.indexOf(
  //         "https://fonts.googleapis.com/css?family=Roboto"
  //       ) === 0
  //     ) {
  //       return;
  //     }

  //     insertBefore.call(head, newElement, referenceElement);
  //   };
  // };

  handleChange = (map) => {
    if (this.props.isInResults) {
      const { coordinates, path } = this.props;

      addPdfElement(this.name, {
        center: map.center,
        marker: { lat: coordinates[0], lng: coordinates[1] },
        zoom: map.zoom,
        paths: path.map((p) => p.content),
      });
    }
  };
  render() {
    const { coordinates, isInResults } = this.props;
    return (
      // Important! Always set the container height explicitly
      <div
        className={`${isInResults ? "results-map" : ""}`}
        style={{
          height: "500px",
          width: isInResults ? "75%" : "100%",
          margin: "auto",
        }}
        ref={this.refName}
      >
        <GoogleMapReact
          bootstrapURLKeys={bootstrapURLKeys}
          defaultCenter={{ lat: 46.616545, lng: 2.728726 }}
          defaultZoom={19}
          center={{ lat: coordinates[0], lng: coordinates[1] }}
          yesIWantToUseGoogleMapApiInternals={true}
          onGoogleApiLoaded={({ map, maps }) => this.showMap(map, maps)}
          options={this.getOptions()}
          key={randomString.generate(10)}
          onChange={this.handleChange}
        />
      </div>
    );
  }

  componentWillUnmount() {
    let scripts = document.querySelectorAll(
      "script[src*='maps.googleapis.com/']"
    );
    for (let i = 0; i < scripts.length; i++) {
      scripts[i].parentNode.removeChild(scripts[i]);
    }
  }
}

export default MapCreator;
