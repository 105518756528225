import React from "react";
import { Icon } from "react-bulma-components/full";

const PasswordVisibilityStyles = {
    pointerEvents: "initial",
    cursor: "pointer",
    right: 0,
    top: 0
}

export default ({ isHidden, onClick }) => {
    return (
        <Icon align="right" style={PasswordVisibilityStyles} onClick={onClick}>
            <i className={`fa ${isHidden ? "fa-eye" : "fa-eye-slash"}`} />
        </Icon>
    )
}