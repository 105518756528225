import dayjs from "dayjs";
import Randomstring from "randomstring";
import { getTCSPEFromSubscribedPowers } from "../../utils/Utils";
export const defaultAreaOfTensionModel = (data, site, Enedis) => {
  let startDate = dayjs().subtract(2, "y");
  startDate = dayjs(startDate).format();
  let endDate = dayjs().format();
  const updatedSite = {
    ...site,
    dataSource: "ENEDIS",
    profile: "",

    automaticUpdateError: null,
    _lastUpdate: dayjs().format("DD/MM/YYYY HH:mm"),
    error: "",
    enedisData: true,
    faltyPrm: null,
    reportURL: "",
    startDate: startDate,
    endDate: endDate,
    purchaseAreaOfTension: Enedis.formatAreaOfTension(
      data.situationAlimentation.alimentationPrincipale.domaineTension
        ._attributes.code
    ),
    offPeakHours: data.situationComptage.dispositifComptage.compteurs.compteur
      .programmationHoraire
      ? Array.isArray(
          data.situationComptage.dispositifComptage.compteurs?.compteur
            ?.programmationHoraire?.programmationPosteHoraire
        )
        ? Enedis.filterOffPeakHours(
            data.situationComptage.dispositifComptage.compteurs.compteur.programmationHoraire.programmationPosteHoraire.filter(
              (e) => e._attributes.code === "HC"
            )[0]
          )
        : Enedis.filterOffPeakHours(
            data.situationComptage.dispositifComptage.compteurs.compteur
              .programmationHoraire.programmationPosteHoraire
          ) || [
            {
              id: Randomstring.generate(10),
              startTime: 22,
              endTime: 6,
            },
          ]
      : [
          {
            id: Randomstring.generate(10),
            startTime: 22,
            endTime: 6,
          },
        ],

    ratesOption: Enedis.getRatesOption(
      data.situationContractuelle.structureTarifaire
        .formuleTarifaireAcheminement._attributes.code
    ),
    purchaseContractType: Enedis.getPurchaseContractType(
      data.donneesGenerales.segment._attributes.code
    ),
    subscribedPowers: Enedis.getSubscribedPowers(
      data.situationContractuelle.structureTarifaire.denivelePuissances
        .classesTemporelles.classeTemporelle,
      Enedis.formatAreaOfTension(
        data.situationAlimentation.alimentationPrincipale.domaineTension
          ._attributes.code
      )
    ),
    subscriptionRates: 0,
    energyPrices: [
      {
        id: Randomstring.generate(10),
        PTE: 0,
        HPH: 0,
        HCH: 0,
        HPB: 0,
        HCB: 0,
      },
    ],
    connectingPower:
      data.situationAlimentation.alimentationPrincipale
        .puissanceRaccordementSoutirage.valeur._text,
  };
  updatedSite.TCSPE = getTCSPEFromSubscribedPowers(
    updatedSite.subscribedPowers
  );
  return updatedSite;
};
