import React, { useState } from "react"
import { URL_UPDATE } from "../../constants/Auth"
import Auth from "../../services/Auth"

const SendMailUpdate = ({ token }) => {
  const [result, setResult] = useState("")
  const [color, setColor] = useState("green")
  const [isLoading, setIsLoading] = useState(false)
  const [id, setId] = useState(Auth.getUserId())
  const [plan, setPlan] = useState("DEMO")
  const [allowedNumberOfSites, setAllowedNumberOfSites] = useState(1)
  const [optimNumber, setOptimNumber] = useState(1)
  const [updatedValue, setUpdatedValue] = useState(10)

  const send = () => {
    setIsLoading(true)
    fetch(URL_UPDATE, { method: "POST", headers: { "Authorization": token }, body: JSON.stringify({ id, plan, allowedNumberOfSites, optimNumber, updatedValue }) })
      .then(res => {
        setColor(res.ok ? "green" : "red")
        res.text()
          .then(txt => {
            try {
              setResult(JSON.stringify(JSON.parse(txt), null, '\t'))
              setIsLoading(false)
            } catch {
              setResult(txt)
              setIsLoading(false)
            }
          })
          .catch(err => {
            setColor("red")
            setResult(JSON.stringify(err))
            setIsLoading(false)
          })
      })
      .catch(err => {
        setColor("red")
        setResult(JSON.stringify(err))
        setIsLoading(false)
      })
  }
  return (
    <div className="cloud-functions-tester-container">
      {isLoading && <div class="cloud-functions-tester-loader"></div>}
      {!isLoading &&
        <>
          <label htmlFor="id">id:</label>
          <input id="id" type="text" value={id} onChange={(({ target }) => setId(target.value))} />
          <label htmlFor="plan">plan:</label>
          <input id="plan" type="text" value={plan} onChange={(({ target }) => setPlan(target.value))} />
          <label htmlFor="allowedNumberOfSites">allowedNumberOfSites:</label>
          <input id="allowedNumberOfSites" type="number" value={allowedNumberOfSites} onChange={(({ target }) => setAllowedNumberOfSites(target.value))} />
          <label htmlFor="optimNumber">optimNumber:</label>
          <input id="optimNumber" type="number" value={optimNumber} onChange={(({ target }) => setOptimNumber(target.value))} />
          <label htmlFor="updatedValue">updatedValue:</label>
          <input id="updatedValue" type="number" value={updatedValue} onChange={(({ target }) => setUpdatedValue(target.value))} />
          <hr />
          <label htmlFor="token">Response:</label>
          <textarea cols="30" rows="10" id="textarea" type="text" style={{ color }} value={result} />

          <button id="send" onClick={send} >SEND</button>
        </>}
    </div>
  )
}

export default SendMailUpdate