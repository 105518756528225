import LoadCurveHeaders from "../graph/LoadCurveHeaders";
import OpexLabels from "../graph/OpexLabels";
import PdfElements from "../graph/PdfElements";
import BusinessPlan from "../files/BusinessPlan";
import EnergeticMix from "../files/EnergeticMix";
import { fr_Hypothesis } from "../files/Hypothesis";
import { fr_tips } from "../tips/TipsTranslation";
import { fr_technologies } from "../technologies/TechnologiesTranslation";
import { fr_captions } from "../captions/CaptionsTranslation";
import { fr_cta } from "../cta/ctaTranslations";
import { fr_notifications } from "../notifications/NotificationsTranslations";
import { create } from "draft-js/lib/EditorState";
import { filter } from "jszip";
export default {
  translations: {
    email: "Email",
    newEmail: "Nouvel email",
    checkEmail: "Vérifier le nouvel email",
    color: "Couleur",
    fromYear: "A partir de l'année...",
    energyInflationIs: "L'inflation du tarif d'achat est de ... (%/an)",
    deleteProjects:
      "Attention la suppresion des {{count}} projets, êtes-vous sûr de vouloir continuer ?",
    warningDelete: "⚠️ Attention les projets seront supprimés définitivement",
    price: "Montants exprimés ",
    //Mois de l'année abrégés
    Jan: "Jan",
    Fev: "Fev",
    Mar: "Mar",
    Avr: "Avr",
    Mai: "Mai",
    Juin: "Juin",
    Jul: "Jul",
    Aout: "Aout",
    Sep: "Sep",
    Oct: "Oct",
    Nov: "Nov",
    Dec: "Dec",
    from: "De",
    to: "à",
    //Mois forme pleine :
    january: "janvier",
    february: "février",
    march: "mars",
    april: "avril",
    may: "mai",
    june: "juin",
    july: "juillet",
    august: "aout",
    septembre: "septembre",
    september: "septembre",
    october: "october",
    november: "november",
    december: "décembre",
    // reverse list ,
    // janvier: "january",
    // février: "february",
    // mars: "march",
    // avril: "april",
    // mai: "may",
    // juin: "june",
    // juillet: "july",
    // août: "august",
    // octobre: "october",
    // novembre: "november",
    // décembre: "december",
    noMonthError: "Merci de sélectionner au moins un mois",
    //jours de la semaine
    dimanche: "dimanche",
    lundi: "lundi",
    mardi: "mardi",
    mercredi: "mercredi",
    jeudi: "jeudi",
    vendredi: "vendredi",
    samedi: "samedi",
    //reverse
    sunday: "dimanche",
    monday: "lundi",
    tuesday: "mardi",
    wednesday: "mercredi",
    thursday: "jeudi",
    friday: "vendredi",
    saturday: "samedi",
    // general
    city: "Ville",
    language: "Langue",
    name: "Nom",
    country: "Pays",
    address: "Adresse",
    currency: {
      label: "Devise",
      symbol: "{{currency, price, currency}}",
      value: "{{value, price, USD}}",
    },
    folder: "Dossier",
    description: "Description",
    cO2: "Taux de CO2 (g/kWh)",
    cO2Base: "Base éCO2mix de RTE",
    next: "Suivant",
    previous: "Précédent",
    apply: "Valider",
    test: "testFR",
    day: "jour",
    days: "jours",
    year: "année",
    years: "ans",
    month: "mois",
    months: "mois",
    reset: "Réinitialiser",
    applyDefaultRates: "Appliquer la structure tarifaire par défaut",
    startTime: "heure de début",
    endTime: "heure de fin",
    addARow: "Ajouter une ligne",
    export: "Exporter",
    reloadPage: "Réinitialiser la mise en page",
    exportToPDF: "Exporter en PDF",
    downloadPDF: "Télécharger PDF",
    downloadJSON: "Télécharger JSON",
    downloadGraphics: "Télécharger les graphiques",
    elementsToInclude: "Eléments à inclure dans le PDF",
    generalElements: "Eléments généraux",
    scenarioSpecificElements: "Eléments spécifiques au scénario",
    modify: "Modifier",
    duplicate: "Dupliquer",
    delete: "Supprimer",
    save: "Sauvegarder",
    cancel: "Annuler",
    close: "Fermer",
    saveAndClose: "Enregistrer et fermer",
    mustBeGreaterThan: "Doit être supérieur à ",
    mustBeInferiorTo: "Doit être inférieur à ",
    mustBeGreaterThanPreviousRow: "Doit être supérieur à la ligne précédente",
    mustBePositive: "Doit être positif",
    "%/year": "%/an",
    "/year": "/an",
    "per year": "par an",
    between: "Entre",
    power: "Puissance",
    advancedConfiguration: "Configuration avancée",
    HT: "HTVA",
    loading: "Chargement...",
    overTheYear: "Sur l'année",
    upTo: "jusqu'à",
    search: "Rechercher",
    searchAProject: "Rechercher un projet",
    hour: "heure",

    //
    analysis: "Analyse",
    scenarios: "Scénarios",
    optimization: "Optimisation",
    existingInstallation: "Installation existante",
    admin: "Administrateur",
    help: "Aide",
    connectedAs: "Connecté en tant que",
    myAccount: "Mon compte",
    mySettings: "Paramètres",
    myContactDetails: "Profil et préférences",
    myCredentials: "Mes identifiants",
    myPlan: "Mon forfait",
    myPrivacy: "Vie privée",
    termsOfService: "Conditions générales d'utilisation",
    myCustomization: "Personnalisation",
    logOut: "Se déconnecter",
    home: "Accueil",
    welcome: "Bienvenue",
    newProject: "Créer un projet",
    myProjects: "Mes projets",
    consumptionOverview: "Analyse",
    energyAssessment: "Bilan énergétique",
    chooseProjectAnalysis: "Choisir un projet pour lancer l'analyse",
    loadingData: "Analyse de vos données...",
    fetchingProjects: "Récupération de vos projets",
    duplicateProject: "Copie de votre projet en cours",
    analysisMayTakeUpTo20Seconds:
      "L'opération peut prendre environ 20 secondes. Merci de patienter",
    logo: "Mon logo",
    changeLogo: "Modifier le logo à afficher sur les exports PDF",
    changePassword: "Modifier mon mot de passe",

    uploadLogo: "Charger mon logo (format png/jpg)",
    logoUploaded: "Votre logo a bien été chargé",
    language: "Ma langue",
    currentLogo: "Logo actuel",
    slot: "Tranche",
    kWc: "kWc",

    //Project list
    update: "Actualiser",
    clicking: "en cliquant ",
    refresh: "ici",
    settings: "Modifier",
    analyze: "Analyser",
    downloadCurrentHistory: "Historique de consommation",
    optimize: "Optimiser",
    letsCreate: "Pour commencer, créer un premier projet !",
    createFirstProject: "Cliquer ici pour créer un premier project",
    projectLimitReached:
      "Vous avez atteint le nombre maximum de projets autorisés.",
    youCanCreate: " Vous avez créé ",
    additionnalProject: "projet supplémentaire",
    additionnalProjects: "projets supplémentaires",
    tokenLimitReached: "Votre nombre de jetons d'analyse est écoulé",
    askForQuote: "Demander un devis",
    askForDemo: "Demander une Démo",
    updateTokenNumber: "Cliquez ici pour mettre à jour votre nombre de jetons",
    extractingData:
      "L'extraction de vos données est en cours, veuillez patienter",
    extractingDataTime: " dans {{timeRemaining}}",
    completeProjectCreation: "Veuillez terminer la configuration du projet",
    anErrorHasOccured:
      "Erreur de connexion avec Enedis, veuillez contacter le support ou actualiser en cliquant ",
    faultyProject: "Le projet a une erreur",
    shiftClick: "Shift + clic pour se déplacer",
    copyOF: "Copie de ",
    //My account
    defaultScenarios: "Scénarios types",
    defaultRates: "Tarifs par défaut",

    newRate: "Nouvelle structure tarifaire",
    pleaseNameRate: "Veuillez donner un nom à votre structure tarifaire",
    // Project editor
    Project: "Projet",
    step: "étape",
    outOfPreposition: "sur",
    projectInfo: "Informations sur le projet",
    consumer: "consommateur",
    supplier: "producteur",
    selfConsumptionProject: "Autoconsommation individuelle",
    consumerProject: "Projet consommateur",
    supplierProject: "Projet producteur",
    fullSellOutProject: "Vente totale",
    consumer_plusProject: "Autoconsommation collective",
    seeListOfCounters: "Voir la liste des compteurs",
    movePin: "Déplacer le marqueur pour ajuster sa position",
    saveProject: "Sauvegarder le projet",
    saveProjects: "Sauvegarder les projets",
    createProject: "Créer le projet",
    consumptionData: "Données de consommation",
    consentFile: "Fichier de consentement",
    step2Explanation:
      "Afin de vous aider à analyser, optimiser et piloter votre projet, nous avons besoin de connaitre votre consommation.",

    step2DataFormat:
      "Merci de respecter un des deux formats suivants avec un intervalle entre chaque mesure variant entre 10min et une heure ",
    downloadConsoDataExample: "Télécharger un exemple",
    yearly: "Annuel",
    monthly: "Mensuel",
    step2UseProfileData: "J'utilise un profil type (référence Enedis)",
    selectProfile: "Sélectionner un profil",
    error_notEnoughData: "Votre fichier ne comporte pas assez de données",
    cantSendNumber: "J'utilise un fichier csv",
    sendMyData: "Envoyer mon historique de consommation (format .csv)",
    dataWasSent: "Fichier importé",
    unkownAddress: "L'adresse est introuvable",
    dataSource: "Source actuelle des données utilisées :",
    electricityMeter: "Enedis via le numéro du PDL",
    dataFile: "fichier .csv importé",
    inputElectricityMeter: "Numéro de compteur",
    numberLengthLimit: "Votre numéro doit comporter 14 chiffres",
    textCheckBox1:
      "Je dispose de l'autorisation expresse du client d'Enedis référencé par ce numéro de compteur, donnée à Likewatt et consentie pour une durée de 12 mois, pour demander et recevoir communication auprès d'Enedis l'historique de courbe de charge, des mesures en kWh et les données techniques et contractuelles du compteur à des fins d'étude",
    textCheckBox2:
      "Le Client dispose de l’autorisation du client ENEDIS référencé par ce numéro de compteur, pour recevoir communication auprès d'Enedis de l'historique de courbe de charge et des données techniques et contractuelles du projet pour des fins d'étude sur une durée de 12 mois.",
    textCheckBox1CandI:
      "Je donne mon autorisation expresse à {{company}} et son partenaire Likewatt, consentie pour une durée de 12 mois, pour demander et recevoir communication auprès d'Enedis l'historique de courbe de charge, des mesures en kWh et les données techniques et contractuelles du PDL référencé ci-dessus à des fins d'étude.",
    textCheckBox2CandI:
      "J'atteste que je suis bien habilité pour donner mon autorisation et que les renseignements fournis me concernent.",
    importEnedisClientConsent: "Importer l'autorisation du client ENEDIS",
    consentUploaded: "Autorisation importée",
    purchasePriceSettings: "Tarifs d'achat",
    regularPrice: "Tarifs classiques",
    customPrice: "Tarifs personnalisés",
    subscriptionPrice: "Prix d'abonnement fournisseur",
    connectingPower: "Puissance de raccordement (kVA)",
    prAmount: "Frais de changement de la puissance de raccordement",
    energyPrice: "Prix du kWh fournisseur",
    subscriptionPriceTB: "Prix d'abonnement",
    energyPriceTB: "Prix du kWh",
    subscribedPower: "Puissance souscrite",
    subscribedPowers: "Puissances souscrites",
    contractType: "Nature du contrat d'acheminement",
    chooseContract: "Choisir un contrat",
    meterOwnership: "Propriété du compteur",
    chooseOwnership: "Choisir un propriétaire",
    voltageRange: "Domaine de tension",
    chooseVoltageRange: "Choisir un domaine",
    tarifOption: "Option tarifaire",
    chooseTarifOption: "Choisir une option",
    CSPERate: "Tarif d'accise sur l'électricité",
    hoursTooltip:
      "Le nombre de tarifs à remplir est en fonction du nombre de tranches horaires (1, 2, 4 ou 5) qui dépend du domaine de tension et de l’option tarifaire détectée pour le projet en question à partir de son numéro de compteur. \n\n\n Dans le cas de tarifs uniques ou non saisonnalisés, copier ces valeurs dans les cases dédiées aux autres tranches horaires",
    pdlToolTip:
      "Le numéro correspond sur la facture au Point de livraison (PDL)",
    projectsCouldNotBeInitialized:
      "Les projets suivants n'ont pas pu être initialisés",
    projectsConfigurationIncomplete:
      "La configuration des projets suivants est incomplète",
    addMyFirstCounter: "Ajouter mon premier compteur",
    addAnotherCounter: "Ajouter un compteur",
    includeTVA: "Inclure la TVA dans l'étude",
    //ANALYSIS
    startDate: "Date de début",
    endDate: "Date de fin",
    noSharedRangeMessage:
      "Impossible d'analyser le projet collectif car vos projets n'ont pas de courbe de charge sur une période commune.",
    noVATAbb: "HTVA",
    withVATAbb: "TTC",
    analyzing: "Analyse du projet",
    fetching: "Collecte des données du projet",

    dataAreUpToDate: "Vos données sont à jour",
    extractLatestData:
      "Extraction des dernières données de consommation disponibles",
    PE: "Part variable",
    PP: "Part fixe",
    CO2emissions: "CO2 émis",
    projectHasNoData: "Votre projet n'a pas de données.",
    //EnergyBalance
    PTE: "Heure de pointe",
    HPH: "Heure Pleine Haute Saison (hiver)",
    HCH: "Heure Creuse Haute Saison (hiver)",
    HPB: "Heure Pleine Basse Saison (été)",
    HCB: "Heure Creuse Basse Saison (été)",
    winter: "(hiver)",
    summer: "(été)",
    selfConsumptionExplanation:
      "NB : Les pourcentages affichés représentent le taux d'autoconsommation de chaque mois",
    //OPTIM
    capex: "Capex",
    optimizing: "Optimisation du scénario ",
    dataGeneration10:
      "La génération de vos résultats peut prendre jusqu'à 2 minutes",
    pleaseDontClose: "Merci de ne pas fermer et de ne pas changer de page",
    scenarioError: "Erreur du scénario",
    optimizationError: "Erreur d'optimisation pour le scénario : ",
    modifyScenario: "Retour au scénario",
    modifyHome: "Retour à l'accueil",
    savingResults: "Nous sauvegardons vos résultats, merci de patienter...",
    noScenariosFound: "Aucun scénario n'a été trouvé !",
    //SCENARIOS
    chooseProject: "Choisir un projet",
    loadingScenarios: "Chargement des scénarios existants...",
    createFirstScenarioMessage:
      "Pour commencer, créez votre premier scénario !",
    createFirstScenarioButton: "Créez votre premier scénario",
    scenarioDescription_0: "Un scénario est composé ",
    scenarioDescription_1: "Des technologies énergétiques envisagées",
    scenarioDescription_2:
      "Des objectifs économiques, écologiques et techniques souhaités",
    scenarioDescription_3:
      "Du context contractuel ou de participation aux marchés",
    optiwizeDescription:
      "Optiwize optimisera pour chaque scénario le dimensionnement des technologies pour respecter les objectifs et maximiser la rentabilité du projet.",
    unlimitedNumberOfScenario:
      "Le nombre de scénarios pouvant être créés pour chaque projet est illimité.",
    selectScenarios: "Sélectionner des scénarios à calculer",
    consumerScenariosLimit:
      "Vous ne pouvez pas optimiser plus de deux scénarios à la fois",
    supplierScenariosLimit:
      "Vous ne pouvez pas optimiser plusieurs scénarios à la fois",
    optimizeScenarios: "Calculer les scénarios ",
    noMoreOptimisationToken:
      "Votre nombre de jetons d'optimisatoin est écoulé, ",
    clickHereForTokens: "Cliquez ici pour mettre à jour votre nombre de jetons",
    newScenario: "Nouveau scénario",
    newDefaultScenario: "Nouveau scénario type",
    newConsumerScenario: "Nouveau scénario consommateur",
    newProducerScenario: "Nouveau scénario producteur",
    sortByName: "Trier par nom",
    sortByDate: "Trier par date",
    scenariosToUpdateMessage:
      "Suite à une mise à jour de la plateforme, les scénarios suivants ne sont plus à jour :",
    optimizeOneScenario: "Optimiser ce scénario",
    scenarioToUpdateMessage:
      "Ce scénario n'est plus à jour, veuillez le modifier",
    warningIncompleteProject:
      "Veuillez terminer la configuration de votre projet",
    lastModification: "Dernière modification",
    //SCENARIO EDITOR
    colorHelp:
      "Définissez ici la couleur dominante qui sera utilisée sur tous les graphiques de résultats",
    scenarioName: "Nom du scénario",
    scenarioNameWarning: "Le nom de votre scénario ne doit pas être vide",
    scenarioCountersWarning:
      "Les compteurs de scénarios ne doivent pas être vides",
    optimizationYear: "Année d'optimisation",
    optimizationYearWarning: "L'année d'optimisation doit être supérieure à 0",
    projectLifespan: "Horizon du projet",
    projectLifespanWarning: "Merci d'indiquer un horizon pour le projet",
    financialSettings: "Paramètres financiers",
    missingSettingsWarning:
      "Veuillez vérifier et remplir les paramètres suivants :",
    missingInTech: "dans les paramètres",
    yourObjectives: "Autres objectifs",
    clickToAddTechnology: "Ajouter une technologie",
    clickToAddCounter: "Ajouter un compteur ACC",
    clickToAddACICounter: "Ajouter un compteur ACI",
    deleteTechWarning: "Souhaitez-vous supprimer cette technologie ?",
    deleteTechWaver:
      "En confirmant la suppression, toutes les données du réseau configurées dans ce scénario seront perdues.",
    lifespan: "Durée de vie",
    systemLoss: "Pertes systèmes DC/AC (%)",
    productible: "Productible annuel (kWh/kWc)",
    degradRate: "Taux de dégradation",
    batteryAvailability: "Disponibilité de la batterie",
    //OBJECTIVES
    "Alimentation secours": "Alimentation secours min",
    Autoproduction: "Autoproduction min",
    "Réduction CO2": "Réduction CO2 min",
    Effacements: "Effacements",
    "Réglage de fréquence": "Réglage de fréquence",
    Talon: "Talon",
    Mediane: "Mediane",
    Totalité: "Totalité",
    //TECHNOLOGY DESCRIPTION
    Photovoltaïque: "Photovoltaïque",
    PhotovoltaïqueDescription:
      "Transforme le rayonnement solaire en électricité grâce à des cellules intégrées à des panneaux qui peuvent être installés sur des bâtiments ou posés au sol. Sa capacité est très modulaire.",
    EolienDescription:
      "Convertit l’énergie cinétique du vent en énergie mécanique entraînant un générateur qui produit de l’électricité. Une éolienne fournit une puissance de quelques kW jusqu’à 6 MW.",
    BatterieDescription:
      "Accumulateur réversible qui restitue sous forme d’énergie électrique l’énergie chimique générée par réactions électrochimiques. Les technologies Li-ion et Ni/MH ont remplacé le Pb et le Ni/Cd.",
    "Groupe électrogèneDescription":
      "Utilisé dans des zones que le réseau électrique ne dessert pas ou pour garantir une alimentation sans interruption, il se base sur un moteur consommant du Diesel, de l’essence ou du gaz.",
    "Pile à combustibleDescription":
      "La PAC génère de l'électricité grâce à l'oxydation d'un combustible réducteur (l'hydrogène) sur une électrode et la réduction d'un oxydant, tel que l'oxygène de l'air, sur l'autre électrode.",
    ElectrolyseDescription:
      "Système de production et de stockage de l'hydrogène par électrolyse de l'eau en consommant l'électricité, principalement produite par les énergies renouvelables.",
    "Production d'eau chaudeDescription":
      "Système de production d’eau chaude avec de l’énergie photovoltaïque utilisant un chauffe-eau électrique (avec un régulateur) et un accumulateur (stockage thermique). Ce système permet de remplacer une alimentation actuelle en gaz.",
    "Stockage virtuelDescription":
      "Offres de marché où le surplus de production locale n'est pas vendu mais comptabilisé et réutilisé à d'autres instants, moyennant le paiement des taxes de transport (TURPE).",
    "Bornes de rechargeDescription":
      "Une station de recharge de véhicules est une infrastructure équipée d'une — ou plusieurs — borne de recharge permettant la recharge des véhicules électriques.",
    //TECHNOLOGY TITLE
    "Configuration de l'Electrolyse et du stockage d'hydrogène":
      "Configuration de l'Electrolyse et du stockage d'hydrogène",
    techConfiguration: "Configuration {{article}} {{tech}}",
    projectTechnologyCard: "Données relatives à la consommation",
    distributionKey: "Clé de répartition",
    distributionType: "Type de répartition",
    //PROJECT TECHNO
    annualGrowthRate: "Taux de croissance annuel",
    demandMultiplier: "Diminution ou augmentation de la consommation",
    counterCapex: " Diminution ou augmentation de la consommation",
    distributionKey: "Clé de répartition",
    prorata: "Au prorata de la consommation",
    fixed: "Clé fixe",
    variable: "Clé variable",
    //FINANCE
    actualizationRate: "Taux d'actualisation",
    opexInflation: "Inflation des coûts d’exploitation",
    taxRate: "taux d'imposition",
    debtRatio: "Pourcentage d'emprunt",
    interestRate: "Taux d'intérêt",
    debtDuration: "Durée du prêt",
    depreciation: "Amortissement sur durée de vie",
    //PV
    maxSurfaceWarningPower:
      "Votre surface maximale ne permet pas de produire la puissance demandée",
    missingPvInput:
      "Veuillez remplir la case Puissance crête, Cas d'usage, Type de l'installation, et la surface maximale",
    peakPower: "Puissance crête",
    panelNumbers: "Nombre de panneaux",
    panelModel: "Modèle de panneau",
    toOptimize: "A optimiser",
    regulatoryFramework: "Cas d'usage",
    surplus: "Vente en surplus",
    totalProduction: "Vente totale",
    fullConsumption: "Autoconsommation totale",
    aoConsumption: "Appel d'offre autoconsommation",
    AOVS: "Autoconso optimisée (vente en surplus)",
    maxSurfaceWarningAo:
      "Votre surface maximale ne permet pas l'appel d'offre autoconsommation",
    maxSurfaceTooltip:
      "Vous pouvez simplement modifier la surface maximale en décochant la case du calcul automatique",
    noTracker: "Pas de tracker",
    monoTracker: "Tracker mono-axial",
    biTracker: "Tracker bi-axial",
    drawMapIndication:
      "Utilisez la carte pour délimiter vos surfaces les unes après les autres.",
    maxSurface: "Surface totale",
    usableSurface: "Surface utile",
    roofTilt: "Inclinaison du toit",
    specificPowerFile: "Importer fichier de puissance photovoltaïque (.csv)",
    specificConsumptionFile: "Importer fichier de consommation (.csv)",
    changeFile: "Modifier fichier",
    powerFileTooltip:
      "Votre fichier de puissance doit ressembler au fichier suivant, et couvrir une année civile complète au pas horaire : ",
    sellingFileTooltip:
      "Votre fichier de consommation doit ressembler au fichier suivant, et couvrir une année civile complète au pas horaire : ",
    modifiedConsoData:
      "Attention ! Les dates de la consommation modifiée doivent correspondre exactement aux mêmes dates que la consommation initiale",
    manualSetting:
      "Pour modifier manuellement les parts de l'installation, décochez la case de calcul automatique de la surface totale au dessus de la carte",

    toModify: "Modifier manuellement les surfaces",
    hypothesisTooltip: "Cliquez pour télécharger le fichier d'hypothèses",
    bearingsTooltip:
      "L’orientation fait référence au point cardinal qui se trouve face au panneau (nord, sud, est, ouest, nord-est, etc.) ; l’inclinaison correspond à l’angle formé par le panneau avec le sol",
    importTMY: "Importer fichier TMY (.csv)",
    tmyFileTooltip: "Votre fichier TMY doit ressembler au fichier suivant : ",
    importSpotFile: "Fichier prix SPOT (.csv)",
    spotFileTooltip: "Votre fichier doit ressembler au fichier suivant : ",
    complement: "Complément de rémunération",
    yieldSTC: "Rendement STC (%)",
    installationShare: "Part de l'installation",
    panelPeakPower: "P crête panneau (Wc)",
    panelTilt: "Inclinaison panneaux",
    pvMinAutoConsoRate: "Taux d'autoconsommation minimal",
    minSelfConsumptionRateWarning: "Ne doit pas être plus bas que 50%",

    pvMaxAutoConsoRate: "Taux d'autoconsommation maximal",
    panelOrientation: "Orientation panneaux",
    panelWidth: "Largeur panneaux",
    panelLength: "Longueur panneaux",
    panelSurface: "Surface panneaux",
    backtracking: "Backtracking",
    backtrackingTooltip:
      "Contrôle si le tracker a la capacité de backtrack pour éviter l'ombrage d'une rangée à l'autre",

    tiltTracker: "Inclinaison de l'axe de rotation",
    tiltTrackerTooltip:
      "L'inclinaison de l'axe de rotation par rapport à l'horizontal (°)",

    maxAngle: "Angle Max",
    maxAngleTooltip:
      "Angle de rotation maximum du tracker par rapport à sa position horizontale",
    panelInterdistance: "Interdistance panneaux",
    financingType: "Type de financement",
    Location: "Location",
    Investissement: "Investissement",
    PPA: "PPA",
    leasingDuration: "Durée de la location",
    opexDuration: "Durée des Coûts d’exploitation",
    ppaDuration: "Durée du PPA",
    inflation: "Inflation du tarif de vente",
    sellingPriceInflation: "Inflation du tarif de vente",
    sellingPrice: "Tarif de vente",
    aOTarif: "Tarif",
    spotInflation: "Inflation SPOT",
    ppaInflation: "Inflation PPA",
    aoBonus: "Prime appel d'offre autoconsommation",
    trackingSystem: "PV tracker",
    pvInvertorPowerRate: "Puissance de l'onduleur (% de puissance crête)",
    //PPA
    ppaPrice: "Tarif",
    addTurpe: "Ajouter TURPE",
    powerToCover: "Puissance à couvrir",
    penaltyCost: "Coût pénalités",
    missingHourWarning: "Merci d'indiquer une heure",
    profile: "Profil",
    yearlyEnergyToCover: "Energie annuelle à couvrir",
    ppaConsumptionFile: "Fichier profil de puissance (.csv)",

    ppaConsumptionFileTooltip:
      "Votre fichier doit ressembler au fichier suivant : ",
    //NETWORK
    pleaseFillHours: "Veuillez définir les puissances souscrites",
    energyInflation: "Inflation du tarif d'achat",
    peakHours: "Heures pleines",
    offpeakHours: "Heures creuses",
    optimizeSubscribedPowers: "Optimiser les puissances souscrites",
    //GRP_ELEC
    powerInputWarning: "Veuillez remplir la case Puissance",
    averageFuelConsumption: "Consommation moyenne",
    fuelConsumptionUnit: "L de carburant / kWh électrique produit",
    fuelCost: "Prix d'achat du carburant",
    fuelCostInflation: "Inflation prix d'achat du carburant",
    //BATTERY
    missingStorageConversionWarning:
      "Veuillez remplir les cases Stockage et Conversion",
    storage: "Stockage",
    capacity: "Capacité",
    minimumSOC: "SOC minimal",
    maximumSOC: "SOC maximal",
    roundTripYield: "Rendement round trip",
    conversion: "Conversion",
    conversionYield: "Rendement conversion (%)",
    batteryDegradRate: "Taux de dégradation de la batterie",
    capacityMechanism: "Mécanisme de capacité",
    referencePrice: "Prix de référence",
    batterySetPointPower: "Puissance de consigne",
    monthlyBilling: "Facturation mensuelle",
    //WINDMILL
    capacityPower: "Puissance",
    windmillHeight: "Hauteur de l'éolienne (m)",
    turbineYield: "Rendement",
    minTurbineNumber: "Nombre d'éoliennes min",
    maxTurbineNumber: "Nombre d'éoliennes max",
    turbineNumber: "Nombre d'éoliennes",

    mustBeGreaterThanMaxTurbineNumber:
      "Doit être inférieur au nombre d'éoliennes maximum",
    mustBeGreaterThanMinTurbineNumber:
      "Doit être supérieur au nombre d'éoliennes minimum",
    turbineType: "Type de turbine",
    minVerticalClearance: "Garde au sol minimum",
    mustBeLesserThanWingTipHeight:
      "Doit être inférieur à la hauteur bout de pale",
    wingTipHeight: "Hauteur bout de pale",
    mustBeGreaterThanVerticalClearance: "Doit être supérieur à la garde au sol",
    mastHeight: "Hauteur du mât",
    mastHeightWarning: "Veuillez spécifier une hauteur de mât",
    fieldAvailability: "Disponibilité du parc",
    shearCoefficient: "Coefficient de cisaillement",
    technicalLosses: "Pertes techniques",
    wakeLosses: "Pertes par sillage",
    environmentalLosses: "Pertes par bridage environmental",
    acousticLosses: "Pertes par bridage acoustique",
    electricalLosses: "Pertes électriques",
    otherLosses: "Autres pertes",
    addWtType: "Ajouter un type d'éolienne",
    //FUELCELL
    h2FuelConsumptionUnit: "kg d'hydrogène / kWh électrique produit",
    h2PurchasePrice: "Prix d’achat de l’hydrogène",
    h2PurchaseInflation: "Inflation prix d'achat de l'H2",
    fuelCellAverageH2Consumption: "Consommation moyenne",

    //ELECTROLYSIS
    electrolysisWarning:
      "Veuillez remplir les cases Puissance de l'Electrolyse et Capacité du Stockage d'Hydrogène",
    electrolysis: "Electrolyse",

    accumulator: "Accumulateur",
    hydrogenStorage: "Stockage d'hydrogène",
    averageConsumption: "Consommation moyenne",
    electrolysisUnit: "kWh électrique / kg H2",
    hydrogenSellingPrice: "Prix de vente de l'hydrogène",
    h2SellingFile: "Importer profil de demande H2 (format .csv)",
    sellingFiletooltip: "Votre fichier doit ressembler au fichier suivant : ",
    // sellingPriceInflation: "Inflation du prix de vente",

    //ECS
    ecsWarning:
      "Veuillez remplir les cases Puissance de la Production d'eau chaude et Capacité du Stockage thermique",
    ecs: "Production d'eau chaude",
    ecsUnit: "kWh électrique / m³ d'eau chaude",
    thermalStorage: "Stockage thermique",
    thermalSellingPrice: "Coût chauffage gaz",
    thermalSellingFile:
      "Importer le fichier de profil de besoin en eau chaude (format .csv)",

    // CONFIG PROJECT
    importConsoData: "Importer un fichier de consommation modifié (.csv)",
    //VIRTUAL
    vsWarningGeneral:
      "Veuillez remplir les cases Tarif d'abonnement et Tarif fourniture d'énergie",
    vsFixedPrice: "Prix fixe",
    vsSuppliedEnergyRate: "Prix sur énergie fournie",
    vsPvRate: "Prix sur puissance PV",
    vsMaxCapacity: "Capacité maximum",
    vsTurpeSwitch:
      "Rajouter coûts d'acheminement et taxes sur l'énergie du stockage virtuel",
    unlimited: "illimitée",
    //STATIONS
    addStationType: "Ajouter un nouveau type de bornes",
    numberOfStations: "Nombre de bornes",
    usageProbability: "Probabilité d'utilisation",
    drivable: "Pilotable",
    addUseCase: "Ajouter une utilisation",
    //RESULTS
    scenarioResults: "Résultat du scénario",
    resetPageLayout: "Réinitialiser la mise en page",
    withdrawIndicator: "Capacité d'effacement engagée",
    energyOnNebef: "Energie effacée sur le marché Nebel",
    totalCostFirstYear: "Détails des coûts et des revenus",
    totalSavingsFirstYear: "Détails des économies de la première année",
    costsBeforeOptim: "Coût pre-optimisation",
    costsAfterOptim: "Coût post-optimisation",
    detailsCostsAfterOptim: "Détails des revenus et des dépenses",
    optimizedSubscribedPowers: "Puissances souscrites optimisées",
    originSubscribedPowers: "Anciennes puissances souscrites",
    objective: "Objectif",
    reached: "Atteint",
    elementsToDisplay: "Données à afficher",
    //CHARTS
    supplyCost: "Fourniture",
    tax: "Taxe",
    turpe: "Turpe",
    network: "Réseau",

    outputInputEnergy: "Energie fournie",
    capacityInstalled: "Capacité",
    numberOfUnits: "Nombre d'unités",
    timeOfDay: "Heure de la journée",
    numberOfHours: "Nombre d'heures",
    model: "Modèle",
    area: "Surface",
    yield: "Rendement",
    energyConsumption: "Puissance soutirée",
    inKW: "en kW",
    heel: "Talon",
    max: "Max",

    vanGraph: "Gains cumulés actualisés",
    ccf: "Gains cumulés",
    beforeOptim: "Facture initiale",
    displayAll: "Tout afficher",
    hideAll: "Tout désélectionner",

    clickOnScenarioName:
      "Cliquer sur le nom du scénario dans le tableau pour afficher les détails",
    referenceYear: "Année de référence",
    powerCovered: "Puissance couverte",
    energyCovered: "Energie couverte",
    //techSummary
    suppliedConsumedEnergy: "Energie fournie",
    selfConsumptionThrottling: "Autoconsommation/Bridage",
    surface: "Surface",
    selfConsummed: "Autoconsommée",
    sold: "Vendue",
    clipped: "Ecrétée",
    clippedM: "Ecrété",
    totalConsumption: "Consommation totale",
    includingBattery: "dont pertes batterie ",
    includingElectrolysis: "dont consommation électrolyse ",
    includingECS: "dont consommation ECS ",
    includingStations: "dont consommation bornes de recharge",
    potential: "Productible",
    opexTooltip:
      "NB : Ces chiffres correspondent à une projection annuelle (12 mois), ils peuvent donc ne pas correspondre à ceux affichés dans la partie analyse si celle-ci ne s'effectue pas sur une année complète",
    selfConsumption: "Autoconsommation",
    //PDF
    optimReport: "Rapport d'optimisation",
    projectName: "Nom du projet",
    projectAddress: "Adresse du projet",
    yearlyCostForecast: "Projection du coût total annuel",
    scenarioName: "Scénario",
    energyMix: "Mix énergétique",
    yearlyCost: "Coût total annuel",
    powerDistribution: "Courbes de puissances",

    ppaGraph: "Couverture PPA",
    commentary: "Commentaires :",
    optimizationReport: "Résultat du scénario",
    optimizationComparaison: "Comparaison des scénarios",
    freeCashflow: "Flux de trésorerie",

    //OPEX
    ...OpexLabels.fr,
    //SUPPLIER LOADCURVE
    ...LoadCurveHeaders.fr,

    //Scenario Table
    projectScope: "Durée de projection (ans)",
    projectNpv: "Gains cumulés actualisés",
    financialIrr: "TRI fonds propres",
    projectIrr: "TRI projet",
    projectBreakEven: "Temps de retour actualisé (ans)",

    capexAbbr: "Dépenses d'investissement",
    triAbbr: "Taux de rentabilité interne",
    ...PdfElements.fr,
    //FILE NAME
    powers10: "Année ",
    powers1: "Année 1",
    downloadData: "Télécharger les données horaires",
    solutions: "Mix énergétique",
    downloadSolutionsFinance: "Télécharger le bilan financier",
    exportDataToXlsx: "Exporter les données en xlsx",
    solutionsFinance: "Bilan financier",
    prettySolutions: "Mix énergétique",
    filesToDownload: "Fichiers à télécharger",

    //BUSINESSPLAN
    ...BusinessPlan.fr.consumer,
    ...BusinessPlan.fr.supplier,
    ...EnergeticMix.fr.consumer,
    ...EnergeticMix.fr.supplier,

    //MYACCOUNT
    availableProjects: "projet(s) disponible(s) sur ",
    selectPlan: "Sélectionnez le forfait qui vous convient",
    Demo: "Démo",
    Projects: "Projets",
    projects: "projets",
    project: "projet",
    Essential: "Essentiel",
    Standard: "Standard",
    Premium: "Premium",
    "12MonthAccess": "12 mois d'accès",
    projectsSimultaneously: "projets créés simultanément",
    oneUserOnly: "Un seul utilisateur connecté à la fois",
    unlimitedProjects: "Illimité en projets créés",
    unlimitedUsers: "Illimité en utilisateurs connectés",
    receiveQuote: "Recevoir un devis",
    changeCredentials: "Modifier ses identifiants",
    password: "Mot de passe",
    newPassword: "Nouveau mot de passe",
    changeInfos: "Mes coordonnées",
    firstname: "Prénom",
    lastname: "Nom",
    code: "Code de référence",
    phone: "Téléphone",
    company: "Entreprise",
    zipCode: "Code Postal",
    noLogoAdded: "Aucun logo n'a encore été ajouté.",
    //optim messages
    Lancement: "Lancement",
    solved: "étape 5/5",
    yearBP: "Année",
    backToScenarioPage: "Revenir à Scénarios",
    backToHomePage: "Revenir à la page d'accueil",

    //ERROR MESSAGES
    separatorShouldBe: "Le séparateur de votre fichier doit être",
    currently: "actuellement",
    emptyOrIncompleteLine: "Des lignes sont vides, ou incomplètes ",
    andMoreLines: " ligne {{firstLine}} et {{lines}} autres",
    wrongColumnNumber:
      "Le nombre de colonnes ne correspond pas au format attendu",
    insteadOf: "au lieu de",
    invalidDateAtLine: "Date invalide à la ligne",
    dateFormatMustBe: "Le format doit être jj/mm/aaaa",
    noDateBefore2020: "La date ne doit pas être antérieure au 1 janvier 2020",
    invalidHourAtLine: "Heure invalide à la ligne",
    errorAtLine: "Erreur à la ligne",
    useValuesInWattsNoDecimal:
      "merci d'utiliser des valeurs en watt sans virgule",
    useValuesWithPointForDecimal:
      "merci d'utiliser des points et non des virgules",
    actualizationError: "ou actualiser en cliquant",
    // use this to convert mail subject to mailto string  https://mailto.vercel.app/
    mailForConsent:
      "mailto:?body=Autorisez%20vous%20notre%20partenaire%20Likewatt%20%C3%A0%20recevoir%20d%E2%80%99Enedis%2C%20%C3%A0%20des%20fins%20d%E2%80%99%C3%A9tude%20et%20pour%20une%20dur%C3%A9e%20de%2012%20mois%20%3A%201)%20l%E2%80%99historique%20de%20la%20courbe%20de%20charge%202)%20l%E2%80%99historique%20des%20mesures%20en%20kWh%20et%203)%20les%20donn%C3%A9es%20techniques%20et%20contractuelles%20de%20votre%20site",
    ...fr_Hypothesis.consumer,
    ...fr_Hypothesis.supplier,
    forABetterExperience:
      "Pour une meilleure expérience, créez un raccourci vers Likewatt en faisant glisser ce logo sur votre bureau.",
    addADesktopIcon:
      "ajoutez un raccourci sur votre bureau en faisant glisser ce logo sur votre bureau.",
    asideMenuCreateShortcut: "Raccourci bureau",
    dragAndDropIcon:
      "Faites glisser l'icône sur votre bureau pour créer un raccourci ",
    orFromChrome: "Ou bien sur Chrome",
    parameters: "Paramètres",
    moreTools: "Plus d'outils",
    chromeCreateShortcut: "Créer un raccourci...",
    //PROFILES
    RES_BASE: "Résidentiel Base",
    RES_HP_HC: "Résidentiel HP/HC",
    PRO_BASE: "Professionnel Base",
    PRO_HP_HC: "Professionnel HP/HC",
    ENT_BT_SUP: "Entreprise >36kVa",
    ENT_HTA: "Entreprise HTA",
    pages: {
      home: "Accueil",
      analyze: "Analyse",
      scenarios: "Scénarios",
      results: "Résultats",
      admin: "Admin",
      help: "Aide",
      manage: "Gestion",
    },
    results: {
      seeDetails: "Voir les détails",
      scenarioIsObsolete: "Scenario obsolète",
      timeRNA: "Temps de retour (ans)",
      pdfLandscape: "Générer au format paysage",
      pdfPortrait: "Générer au format portrait",
      pdfZipDownload:
        "Télécharger un zip contenant les graphiques au format .png (sans fond blanc)",
      optimizingProject: "Optimisation de votre projet d'autoconsommation",
      reportDurationInformationACI:
        "Les énergies ci-dessous sont indiquées pour la période sur laquelle les données de consommation sont disponibles seulement. Cette période peut donc ne pas représenter une année complète.",
      reportDurationInformationACC:
        "Les énergies ci-dessous sont calculées uniquement sur la période commune pour laquelle les données de consommation de tous les projets sont disponibles. Cette période peut donc ne pas représenter une année complète.",
    },
    depreciationDuration: "Durée de l'amortissement",
    ...fr_tips,
    ...fr_technologies,
    whatCanIDo: "Que puis-je faire ?",
    listOfProjects: {
      Folders: "Dossiers",
      manageFolders: "Gérer les dossiers",
      yourProjects: "Vos projets",
      createFolder: "Créer un dossier",
      currentFolder: "Dossier actuel",
      selectFolder: "Sélectionner un dossier",
      allFolders: "Tous les dossiers",
      lastModified: "Date de modification",
      createdAt: "Date de création",
      filterbyModification: "Filtrer par date de modification",
      filterbyCreation: "Filtrer par date de création",
      rename: "Renommer",
    },
    selectAll: "Tout sélectionner",
    unSelectAll: "Tout désélectionner",
    navigation: {
      toScenarios: "Vers Scénarios",
      toHome: "Retour à l'accueil",
      toResults: "Vers Résultats",
    },
    editorProject: {
      updatingScenarios: "Mise à jour des scénarios rattachés au projet",
      hasWeekendPricing: "Option weekend",
      tempoPricing: "Option tempo",
      weekPriceHP: "Tarif Semaine HP",
      weekPriceHC: "Tarif Semaine HC",
      basePricing: "Tarif unique",
      peakHours: "Option HP/HC",
      weekendPriceHP: "Tarif Weekend HP",
      weekendPriceHC: "Tarif Weekend HC",
      tempoBluePriceHC: "Bleu HC",
      tempoBluePriceHP: "Bleu HP",
      fetchingData: "Extraction de vos données...",
      prmPopUp: {
        trigger: "Où trouver la référence du point de livraison ?",
        content: "",
      },
      tempoWhitePriceHC: "Blanc HC",
      tempoWhitePriceHP: "Blanc HP",
      tempoRedPriceHC: "Rouge HC",
      tempoRedPriceHP: "Rouge HP",
      pricesAsCentsWarning:
        "Les tarifs doivent être renseignés en centimes d'euro",

      includedTurpe: "Le prix indiqué sur la facture inclut l'acheminement",
      updateSubProject:
        "La mise à jour de ce paramètre sera appliqué à tous les sous-projets dans le projet.",
      whereCanIFindPDL: "Où trouver le numéro du PDL ?",
      restOfTheYear: "Reste de l'année",
    },
    loadCurveInterface: {
      general: {
        zoomOut: "Dézoomer",
        jan: "Jan",
        feb: "Fév",
        mar: "Mar",
        apr: "Avr",
        may: "Mai",
        jun: "Juin",
        jul: "Juil",
        aug: "Aou",
        sep: "Sep",
        oct: "Oct",
        nov: "Nov",
        dec: "Dec",
        weekdays: {
          sunday: "Dimanche",
          monday: "Lundi",
          tuesday: "Mardi",
          wednesday: "Mercredi",
          thursday: "Jeudi",
          friday: "Vendredi",
          saturday: "Samedi",
        },
      },
    },
    homePage: {
      consentDemoWaiver:
        "Pour activer votre essai, merci d'envoyer le consentement de votre client propriétaire du numéro de compteur à ",
      consentFileIsMissing: "Veuillez recueillir le consentement du client. ",
      limitDate: "Date limite avant blocage du projet",
    },
    myAccountPage: {
      customization: {
        badLogoFormat: "Le logo doit être au format jpg ou png",
      },
      dashboard: {
        connectedAs: "Connecté en tant que : ",
        myPlan: "Ma licence :",
      },
    },
    consent: {
      infoConsent1: "Dans le cadre d'une étude photovoltaique, l'entreprise",
      infoConsent2:
        " souhaite avoir votre consentement pour accéder aux données de consommation électrique de ce compteur PDL",
      infoConsent3: " auprès d'Enedis.",
      authorize1: "J'autorise Enedis à communiquer directement à l'entreprise",
      authorize2:
        " via le logiciel Optiwize (édité par Likewatt) les données ci-dessous :",
      consent1:
        "L’historique des mesures, en kWh, du projet (et puissances atteintes et dépassements de puissance)",
      consent2:
        "L’historique des relevés d’index quotidiens, en kWh, et la puissance maximale quotidienne, en kVA ou kWh, du projet",
      consent3:
        "L’historique de courbe de charge, au pas restitué par Enedis, du projet",
      consent4:
        "Les données techniques et contractuelles disponibles du projet",
      consentReceived:
        "Nous avons bien reçu votre autorisation. Merci pour votre confiance et bonne chance pour vos projets d'ENR !",
      removeConsent:
        "Votre autorisation est valable pour une durée de 12 mois. Vous pouvez à tout moment retirer votre autorisation en envoyant un message à",
      sendAutorization: "Je valide mon autorisation",
      alreadyConsent: "Nous avons déjà reçu votre consentement le ",
      alreadyConsent2: "Merci pour votre confiance",
    },
    duplicateScenarios: "Copie des scénarios en cours",
    info: {
      fetchingTimezone: "Récupération du fuseau horaire...",
      disableOptimToUploadFile:
        "Merci de décocher la case Optimisation afin de pouvoir charger un fichier de puissance photovoltaïque",
      fileWontBeUsedWarning:
        "Attention si l'optimisation est activée, nous ne prenons plus le profil de puissance en compte",
      fileInformation:
        "Le fichier doit couvrir une année civile complète, au pas horaire.",
      wtFileInformation:
        "Veuillez fixer une puissance crête >0 avant.\n Le fichier doit couvrir une année civile complète, au pas horaire.",
      pvFileInformation:
        "Veuillez fixer une puissance crête >0 avant.\n Le fichier doit couvrir une année civile complète, au pas horaire.\n Les fichiers en sortie de SolarEdge Designer sont acceptés.",

      pdfInformation:
        "Pour un pdf de meilleure qualité, merci de mettre la fenêtre de l'application en plein écran.",
      licenceWillExpireIn:
        "Votre licence arrivera à expiration dans {{number}} {{unit}}, vos projets actuels \n seront automatiquement supprimés en absence de renouvellement.",
      licenceHasExpired:
        "Votre licence est expirée ! Vos projets actuels seront automatiquement supprimés en absence de renouvellement.",
      onlyOneProjectRemaining: "Attention, il ne vous reste que 1 projet",
      onlyNProjectsRemaining:
        "Attention, il ne vous reste que {{number}} projets",
    },
    outOf: "sur",
    analysisPage: {
      anErrorHasOccurred: "L'analyse a rencontré une erreur",
      pleaseContactSupport:
        "Veuillez contacter le support client en indiquant le numéro d'analyse suivant",
    },
    excel: {
      consumption: "Consommation",
      ofWhich: "dont",
    },
    mail: {
      to: "À",
      object: "Objet",
      company: "Entreprise",
      body: "Corps du mail",
      send: "Envoyer",
      success: "Votre mail a bien été envoyé ✅",
      failure: "Votre mail n'a pas pu être envoyé ❌",
    },

    ...fr_captions,
    ...fr_cta,
    ...fr_notifications,
  },
};
