import React, { useState } from "react"
import ApiService from "../../services/Api"
const GeneralSettings = ({ handleTokenUpdate, token }) => {
  const [updatedToken, setUpdatedToken] = useState(token)

  const handleResetToPersonnal = () => {
    ApiService.getToken()
      .then(token => {
        setUpdatedToken(token)
        handleTokenUpdate(token)
      })
      .catch(err => console.error(err))
  }

  return (
    <div className="cloud-functions-tester-container">
      <label htmlFor="token">Token:</label>
      <textarea cols="30" rows="10" id="token" type="text" value={updatedToken} onChange={({ target }) => setUpdatedToken(target.value)} />

      <button id="update" onClick={() => handleTokenUpdate(updatedToken)} >SAVE</button>
      <button id="resetToPersonnal" onClick={handleResetToPersonnal} >RESET TO PERSONNAL</button>
    </div>
  )
}

export default GeneralSettings