import SiteConstants from "../../../../constants/Site";
import { getDefaultValues } from "../../../../store/defaultValues/DefaultValuesStore";
export const isSiteACollectiveSubSite = (site) => !!site.collectiveSiteId;

export function setTechCosts(techKey, tech, site) {
  const DEFAULT = getDefaultValues(SiteConstants.type.CONSUMER);
  const { settings } = tech;
  const { financingType } = settings;
  const FINANCING_TYPES = Object.keys(DEFAULT.financingType);
  switch (financingType) {
    //Investment
    case FINANCING_TYPES[0]:
      return {
        [`capex${techKey.toUpperCase()}TableV`]: [
          parseFloat(tech.settings.minPower) || 0,
          ...tech.settings.costs.map((e) => parseFloat(e.power)),
        ],
        [`capex${techKey.toUpperCase()}TableA`]: tech.settings.costs.map((e) =>
          parseFloat(e.capex)
        ),
        [`opex${techKey.toUpperCase()}`]: tech.settings.costs.map((e) =>
          parseFloat(e.opex)
        ),

        [`${techKey}SellingPrice`]: tech.settings.costs.map((e) =>
          parseFloat(e.sellingPrice || 0)
        ),
        [`${techKey}PpaBuilderSlots`]: [],

        [`addTurpe${techKey.toUpperCase()}`]: 0,
        [`${techKey}Subventions`]: tech.settings.costs.map((e) =>
          +tech.settings.subventionDuration !== 0
            ? parseFloat(e.subvention ?? 0)
            : 0
        ),
      };

    //Leasing
    case FINANCING_TYPES[1]:
      return {
        [`capex${techKey.toUpperCase()}TableV`]: [
          parseFloat(tech.settings.minPower) || 0,
          ...tech.settings.costs.map((e) => parseFloat(e.power)),
        ],
        [`capex${techKey.toUpperCase()}TableA`]: tech.settings.costs.map(
          (e) => 0
        ),
        [`${tech.settings.iskWh ? "rental" : "opex"}${techKey.toUpperCase()}`]:
          tech.settings.costs.map((e) => parseFloat(e.opex)),

        [`${techKey}SellingPrice`]: tech.settings.costs.map((e) =>
          parseFloat(e.sellingPrice || 0)
        ),
        [`${techKey}PpaBuilderSlots`]: [],

        [`addTurpe${techKey.toUpperCase()}`]: 0,
        [`${techKey}Subventions`]: tech.settings.costs.map((e) =>
          +tech.settings.subventionDuration !== 0
            ? parseFloat(e.subvention ?? 0)
            : 0
        ),
      };

    // PPA
    case FINANCING_TYPES[2]:
      return {
        [`capex${techKey.toUpperCase()}TableV`]: [
          parseFloat(tech.settings.minPower) || 0,
          ...tech.settings.costs.map((e) => parseFloat(e.power)),
        ],
        [`capex${techKey.toUpperCase()}TableA`]: tech.settings.costs.map((e) =>
          parseFloat(e.capex)
        ),
        [`opex${techKey.toUpperCase()}`]: tech.settings.costs.map((e) =>
          parseFloat(e.opex)
        ),

        [`${techKey}SellingPrice`]: tech.settings.costs.map((e) =>
          parseFloat(e.sellingPrice || 0)
        ),
        [`${techKey}PpaBuilderSlots`]: formatPPATarifs(
          tech.settings.ppaTarifs,
          site
        ),
        [`${techKey}Subventions`]: tech.settings.costs.map((e) =>
          +tech.settings.subventionDuration !== 0
            ? parseFloat(e.subvention ?? 0)
            : 0
        ),
      };

    default:
      return {};
  }
}

function formatPPATarifs(tarifs, site) {
  if (!tarifs || tarifs.length === 0) return [];
  const isACC = site?.sites?.length > 0;
  let result = {};
  if (!isACC) {
    result.ppaPrice = +tarifs[0].ppaPrice;
  } else {
    //If tarifs existed with the ancient format, we set ppaTarif to the first subSite to 0;
    if (typeof tarifs[0]?.endHour !== "undefined") {
      console.log("Old format found");
      result[site.sites[0].id] = 0;
      return result;
    }
    console.log("New format found");

    tarifs.map((tarif) => {
      if (tarif) {
        result[tarif.siteId] = tarif.ppaPrice;
      }
    });
  }
  return result;
}

export function getRegulatoryFrame(str) {
  switch (str) {
    case "FULL_CONSUMPTION":
      return "Autoconsommation totale";
    case "SURPLUS":
      return "Vente surplus";
    case "TOTAL_PRODUCTION":
      return "Vente totale";
    case "AO_CONSUMPTION":
      return "AO autoconsommation";
    case "AOVS":
      return "AOVS";
    default:
      return "null";
  }
}
export function getPVStructure(str) {
  switch (str) {
    case "ON_BUILDING":
      return "Batiment";
    case "ON_THE_GROUND":
      return "Sol";
    case "IN_THE_SHADE":
      return "Ombrieres";
    default:
      break;
  }
}

export function setSystemLossOrYield(tech) {
  const { systemLossOrProductible, techPrefix } = tech;
  switch (systemLossOrProductible) {
    case "PRODUCTIBLE":
      return {
        [`${techPrefix}Productible`]: parseFloat(tech.productible),
        [`${techPrefix}SystemLoss`]: 0,
      };

    default:
      return {
        [`${techPrefix}Productible`]: 0,
        [`${techPrefix}SystemLoss`]: parseFloat(tech.yieldDCAC) / 100,
      };
  }
}

export function setSTCOrPeakPower(tech) {
  const { STCOrPeakPower, techPrefix } = tech;
  switch (STCOrPeakPower) {
    case "PEAK_POWER":
      return {
        [`${techPrefix}PanelPeakPower`]: parseFloat(tech.panelPeakPower),
        [`panelYield${techPrefix === "pv" ? "" : "2"}`]: 0,
      };

    default:
      return {
        [`${techPrefix}PanelPeakPower`]: 0,
        [`panelYield${techPrefix === "pv" ? "" : "2"}`]:
          parseFloat(tech.yieldSTC) / 100,
      };
  }
}

export function setPVRows(tech) {
  const { pvRows, techPrefix } = tech;
  return {
    [`${techPrefix === "pv" ? "tilt" : "tilt2"}`]: pvRows.map((row) =>
      parseFloat(row.tilt)
    ),
    [`${techPrefix === "pv" ? "orientation" : "orientation2"}`]: pvRows.map(
      (row) => parseFloat(row.orientation)
    ),
    [`${techPrefix}Share`]: pvRows.map((row) => parseFloat(row.share)),
  };
}

export function formatDemandMultiplierSlots(configSite) {
  const DEFAULT = getDefaultValues(SiteConstants.type.CONSUMER);

  if (configSite?.demandMultiplierSlots) {
    return configSite?.demandMultiplierSlots?.map(({ id, ...slot }) => ({
      ...slot,
      months: slot.months.map((m) => m.month),
      demandMultiplier: parseFloat(slot.demandMultiplier),
    }));
  } else {
    return DEFAULT.scenarioConfigSite.demandMultiplierSlots.map((slot) => ({
      ...slot,
      months: slot.months.map((m) => m.month),
      demandMultiplier: parseFloat(slot.demandMultiplier),
    }));
  }
}

export function formatEnergyInflationRates(energyInflationRates = []) {
  const periods = [];
  const rates = [];
  energyInflationRates.map(({ period, energyInflationRate }) => {
    periods.push(+period);
    rates.push(+energyInflationRate);
  });
  return [periods, rates];
}

export function formatEnergyRates(
  areaOfTension,
  energyR,
  ratesOption,
  isOptim
) {
  if (isOptim) return ["null", "null", "null", "null", "null"];
  switch (areaOfTension) {
    case "BT<36 kVA":
      if (
        [SiteConstants.ratesOption.CU4, SiteConstants.ratesOption.MU4].includes(
          ratesOption
        )
      ) {
        return [
          energyR[0].HPH,
          energyR[0].HPH,
          energyR[0].HCH,
          energyR[0].HPB,
          energyR[0].HCB,
        ].map((v) => (isNaN(parseFloat(v)) ? v : parseFloat(v)));
      } else {
        return [
          energyR[0].HPB,
          energyR[0].HPB,
          energyR[0].HCB,
          energyR[0].HPB,
          energyR[0].HCB,
        ].map((v) => (isNaN(parseFloat(v)) ? v : parseFloat(v)));
      }
    case "BT>36 kVA":
      return [
        energyR[0].HPH,
        energyR[0].HPH,
        energyR[0].HCH,
        energyR[0].HPB,
        energyR[0].HCB,
      ].map((v) => parseFloat(v));

    default:
      return [
        energyR[0].PTE,
        energyR[0].HPH,
        energyR[0].HCH,
        energyR[0].HPB,
        energyR[0].HCB,
      ].map((v) => parseFloat(v));
  }
}

export function formatSubscribedPowers(
  areaOfTension,
  subP,
  isOptim,
  tarifBuilder
) {
  if (isOptim || tarifBuilder) return ["null", "null", "null", "null", "null"];
  switch (areaOfTension) {
    case "BT<36 kVA":
      return [
        subP[0].HCB,
        subP[0].HCB,
        subP[0].HCB,
        subP[0].HCB,
        subP[0].HCB,
      ].map((v) => (isNaN(parseFloat(v)) ? v : parseFloat(v)));
    case "BT>36 kVA":
      return [
        subP[0].HPH,
        subP[0].HPH,
        subP[0].HCH,
        subP[0].HPB,
        subP[0].HCB,
      ].map((v) => parseFloat(v));

    default:
      return [
        subP[0].PTE,
        subP[0].HPH,
        subP[0].HCH,
        subP[0].HPB,
        subP[0].HCB,
      ].map((v) => parseFloat(v));
  }
}
