const BREAK_POINT = 1024

export const checkWidth = () => {
  return window.innerWidth < BREAK_POINT;
};

export const toggleClassName = (condition, className) => {
  if (condition == true)
    return ` ${className}`
  return ""
}