import ScenarioService from "../../services/Scenario";
import ScenarioConstants from "../../constants/Scenario";
import { updateScenario } from "../../utils/ScenarioUtils";
import { buildParamsGrid } from "../Optimization/Consumer/TechnologiesParams";
import CollectiveSitesService from "../../services/CollectiveSites";

/**
 *
 * This function updates this site's scenarios if any of the parameters have changed.
 *
 */
export const updateThisSiteScenarios = async (site) => {
  const {
    id,
    collectiveSiteId,
    subscriptionRates,
    energyPrices,
    energyPricesTempo,
    energyPricesWe,
    tempoPricing,
    hasWeekendPricing,
    purchaseAreaOfTension: voltageRange,
    ratesOption,
    subscribedPowers,
    tarifBuilder,
    tarifs: tarifBuilderSlot,
    restOfTheYearTarifs,
    connectingPower,
  } = site;

  if (!collectiveSiteId && !id) return Promise.reject("No site id was given");

  const isSubSite = collectiveSiteId ? true : false;

  const siteScenarios = await ScenarioService.getSiteScenarios(
    collectiveSiteId || id
  );

  if (siteScenarios.length === 0)
    return Promise.resolve("No scenario to update");

  const NETWORK_ID = ScenarioConstants.techTypes.NETWORK.id;
  return Promise.all(
    siteScenarios
      // .filter((scen) => {
      //   // if (!scen.optim_params) return false;
      //   // if (!Object.keys(scen.optim_params).length) return false;
      //   if (isSubSite && !scen.configSite) return false;
      //   if (isSubSite && !Object.keys(scen.configSite).length) return false;
      //   if (isSubSite && !scen.optim_params.paramsGrid?.counters) return false;
      //   if (isSubSite && !scen.optim_params.paramsGrid.counters.length)
      //     return false;
      //   return true;
      // })
      .map(async (scenario, i) => {
        console.log({ scenario });

        let newScenario = {};

        if (isSubSite) {
          // in case of a sub-site (ACC), unless the sub-site was already in the scenario
          // the scenario must not be updated
          if (!scenario.configSite.hasOwnProperty(id)) return;

          // in ACC site's scenario, sub-sites' config are stored in scenario.configSite = {"sub-site-id1": {}, "sub-site-id2": {}}
          if (voltageRange === "BT>36 kVA")
            subscribedPowers[0] = {
              ...subscribedPowers[0],
              PTE: subscribedPowers[0].HPH,
            };

          newScenario = {
            ...scenario,
            configSite: {
              ...scenario.configSite,
              [id]: {
                ...scenario.configSite[id],
                settings: {
                  ...scenario.configSite[id].settings,
                  subscriptionRates,
                  energyPrices,
                  energyPricesTempo,
                  energyPricesWe,
                  tempoPricing,
                  hasWeekendPricing,
                  voltageRange,
                  ratesOption,
                  subscribedPowers,
                  tarifBuilderSlot,
                  restOfTheYearTarifs,
                  tarifBuilder,
                  connectingPower,
                },
              },
            },
          };
        } else {
          // in individual site's scenario, site's config is stored as a NETWORK technologie
          newScenario = {
            ...scenario,
            technologies: scenario.technologies.map((tech) => {
              if (tech.type !== NETWORK_ID) return tech;
              return {
                ...tech,
                settings: {
                  ...tech.settings,
                  subscriptionRates,
                  energyPrices,
                  energyPricesTempo,
                  energyPricesWe,
                  tempoPricing,
                  hasWeekendPricing,
                  connectingPower,
                  voltageRange,
                  ratesOption,
                  subscribedPowers: tech.settings.optimizeSubscribedPowers
                    ? tech.settings.subscribedPowers
                    : subscribedPowers,
                  tarifBuilderSlot,
                  restOfTheYearTarifs,
                  tarifBuilder,
                },
              };
            }),
          };
        }

        // UPDATE paramsGrid
        // if (isSubSite) {
        //   const collectiveSite = await CollectiveSitesService.getById(
        //     collectiveSiteId
        //   );

        //   collectiveSite.sites = collectiveSite.sites.map((subSite) =>
        //     subSite.id === id ? site : subSite
        //   );

        //   const counters = scenario.optim_params.paramsGrid.counters.map(
        //     (subSiteParamsGrid) =>
        //       subSiteParamsGrid.id === id
        //         ? buildParamsGrid(site, newScenario)
        //         : subSiteParamsGrid
        //   );

        //   newScenario.optim_params.paramsGrid = {
        //     counters,
        //   };
        // } else {
        //   const paramsGrid = buildParamsGrid(site, newScenario);
        //   newScenario.optim_params.paramsGrid = paramsGrid;
        // }

        return updateScenario(scenario, newScenario, i);
      })
  );
};

export const getSiteStatusByErrorCode = (site) => {
  if (site.isExtractingData) return "Extraction...";
  let value = site.automaticUpdateError;
  switch (site.automaticUpdateError) {
    case "Report Retrieved":
      value = "OK";
      break;
    case null:
      if (site.enedisNumber && !site.reportURL) {
        value = "No_data";
        break;
      }
      value = "OK";
      break;
    default:
      break;
  }
  return value;
};
