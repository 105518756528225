import dayjs from "dayjs";
import Randomstring from "randomstring";
import { getTCSPEFromSubscribedPowers } from "../../utils/Utils";
export const btInfModel = (data, site, Enedis) => {
  let startDate = dayjs().subtract(2, "y");
  startDate = dayjs(startDate).format();
  let endDate = dayjs().format();
  let updatedSite = {
    ...site,
    profile: "",
    dataSource: "ENEDIS",
    includeTURPE: true,
    _lastUpdate: dayjs().format("DD/MM/YYYY HH:mm"),
    error: "",
    analysisId: "",
    enedisData: true,
    reportURL: "",
    automaticUpdateError: null,
    purchaseAreaOfTension: Enedis.formatAreaOfTension(
      data.situationAlimentation.alimentationPrincipale.domaineTension
        ._attributes.code
    ),
    offPeakHours: data.situationComptage.dispositifComptage.relais
      ? Enedis.getHoursBTINF(
          data.situationComptage.dispositifComptage.relais.plageHeuresCreuses
            ._text
        )
      : [
          {
            id: Randomstring.generate(10),
            startTime: 23,
            endTime: 6,
          },
        ],
    ratesOption: Enedis.getRatesOption(
      data.situationContractuelle.structureTarifaire
        .formuleTarifaireAcheminement._attributes.code
    ),
    purchaseContractType: Enedis.getPurchaseContractType(
      data.donneesGenerales.segment._attributes.code
    ),
    subscribedPowers: [
      {
        PTE: parseInt(
          data.situationContractuelle.structureTarifaire.puissanceSouscriteMax
            .valeur._text
        ),
        HCB: parseInt(
          data.situationContractuelle.structureTarifaire.puissanceSouscriteMax
            .valeur._text
        ),
        HCH: parseInt(
          data.situationContractuelle.structureTarifaire.puissanceSouscriteMax
            .valeur._text
        ),
        HPB: parseInt(
          data.situationContractuelle.structureTarifaire.puissanceSouscriteMax
            .valeur._text
        ),
        HPH: parseInt(
          data.situationContractuelle.structureTarifaire.puissanceSouscriteMax
            .valeur._text
        ),
        id: Randomstring.generate(10),
      },
    ],
    subscriptionRates: 0,
    energyPrices: [
      {
        id: Randomstring.generate(10),
        PTE: 0,
        HPH: 0,
        HCH: 0,
        HPB: 0,
        HCB: 0,
      },
    ],
    connectingPower:
      data.situationAlimentation.alimentationPrincipale
        .puissanceRaccordementSoutirage?.valeur._text ?? null,
  };
  updatedSite.TCSPE = getTCSPEFromSubscribedPowers(
    updatedSite.subscribedPowers
  );
  return updatedSite;
};
