import React, { Fragment, lazy, Suspense } from "react";
import { HashRouter as Router, Route, Redirect } from "react-router-dom";
import Auth from "./services/Auth";
import UserService from "./services/User";
import { fire } from "./fire";
import PageNames from "./constants/Page_names.json";
import loadable from "loadable-components";
import "./App.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Home from "./containers/Home";
import CloudFunctionsTester from "./containers/CloudFunctionsTester";
import { checkPresence } from "./services/Presence";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";
import history from "./history";
import Header from "./components/Header";
import Signin from "./components/Signin";
import Signup from "./components/Signup";
import Signout from "./components/Signout";
import { ShortcutPage } from "../src/containers/ShortcutPage";
import InfoBar from "./components/shared/InfoBar";
import to from "await-to-js";
import detect from "detect.js";
import i18n from "./config/i18n";
import { Translation } from "react-i18next";
import LocaleContext from "./context/LocaleContext";
import Firebase from "firebase";
import * as Utils from "./utils/Utils";
import SiteService from "./services/Site";
import dayjs from "dayjs";
import { EventPopUp } from "./components/Home/EventPopUp";
import { MainSkeleton } from "./components/Skeletons/MainSkeleton";
import "./containers/Analyze.scss";
import { Button, Progress } from "react-bulma-components/full";
import PLANS from "./constants/Plans";
import { ContactButton } from "./components/ContactButtons";
const LOCAL_VERSION = "0.0.7.001.43";
console.log(LOCAL_VERSION);
import * as THEME from "./assets/style/SharedColors";
import Highcharts from "highcharts";
import exporting from "highcharts/modules/exporting";
import { handleRedirects } from "./helpers/Router";
import Api from "./services/Api";
import { setDefaultValues } from "./store/defaultValues/DefaultValuesStore";
import SiteConstants from "./constants/Site";
import { CustomModal } from "./components/CustomModal";
import AuthService from "./services/Auth";
import { CustomNotification } from "./components/CustomNotification";

import { getFileUrlByPath } from "./services/storage";
import { basePathUtils, nameManual } from "./constants/Manual";
import { unsetTutorialAsShown } from "./helpers/Validators";
import { TUTORIALS } from "./constants/Tutorials";
import { ThemeProvider } from "styled-components";
import HomeCandI from "./containers/c&i/HomeCandI";
import Landing from "./containers/c&i/Landing";
import { StyledLoader } from "./assets/style/commonStyledComponent";
import Developpers from "./services/Developpers";
import User from "./services/User";
import { defaultTheme } from "./constants/Theme";
import {
  DEFAULT_GRID_KEYS,
  GRID_REFERENCES,
  SYNTHESIS_FI_KEYS,
} from "./components/shared/ReactGridLayout/constants";
import { setDefaultGridLayout } from "./store/gridLayout/store";
exporting(Highcharts);

// prototype edit
Array.prototype.filterAndMap = function (func) {
  const result = [];
  for (const obj of this) {
    if (func(obj)) {
      result.push(func(obj));
    }
  }
  return result;
};

const analytics = fire.analytics();
const Pilotage = loadable(() => import("./containers/Pilotage"));
const Manage = lazy(() => import("./containers/Manage"));
const Admin = lazy(() => import("./containers/Admin"));
const MyAccount = lazy(() => import("./containers/MyAccount"));
const Analyze = lazy(() => import("./containers/Analyze"));
const Optimization = lazy(() => import("./containers/Optimization"));
const Results = lazy(() => import("./containers/Results"));
const Legal = lazy(() => import("./containers/Legal"));
const Consent = lazy(() => import("./containers/Consent"));
const DataProtection = lazy(() => import("./containers/DataProtection"));

const PrivateRoute = ({ component: Component, theme, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (window.location.hostname.includes("autoconsommation")) {
          if (
            (Auth.isAuthLocally() || Auth.isAuth()) &&
            Object.keys(theme).length !== 0
          ) {
            return (
              <>
                <Component {...props} />
              </>
            );
          } else {
            return (
              <StyledLoader
                width="300px"
                margin="auto"
                marginTop="10vh"
                height="300px"
              />
            );
          }
        } else {
          if (Auth.isAuthLocally() || Auth.isAuth()) {
            return (
              <>
                <Component {...props} />
              </>
            );
          } else {
            return (
              <Redirect
                to={{
                  pathname: "/signin",
                  state: { from: props.location },
                }}
              />
            );
          }
        }
      }}
    />
  );
};

const AdminPage = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      Auth.isAuth() && Auth.getUser().isAdmin ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/signin",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const ManagePage = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      Auth.isAuth() && Auth.getUser().isManager ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/signin",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showInfoBar: false,
      infoBarMessage: null,
      connectedUser: "",
      dbRef: null,
      uid: "",
      sites: [],
      shouldInfoBarBeVisible: false,
      showEventPopUp: false,
      showUpdateButton: false,
      showContactButton: false,
      forcePasswordChange: false,
      timerSendingEmail: 0,
      day: null,
      mailIsSent: false,
      manualURL: "",
      theme: {},
      redirectToHome: false,
    };
    this.zoomLevel = 0;
  }

  changeTheme = async () => {
    const dev = await Developpers.getByDevelopper();
    const user = await Auth.getUser();
    const theme = user.theme ? user.theme : defaultTheme;

    const logo = await User.getLogoWithUserId(dev.account);

    const themeWithLogo = {
      ...theme,
      logo: logo,
    };
    this.setState({ theme: themeWithLogo });
  };

  getUserLanguage = async (user) => {
    if (!Auth.isAuth()) return;
    let err,
      userDb = null;
    [err, userDb] = await to(UserService.get(user.uid));
    // fetch user prefered language from db, if no language, then browser language is chosen
    if (userDb.language) {
      return await i18n.changeLanguage(userDb.language);
    } else {
      return await this.updateUserLocale(userDb);
    }
  };
  updateUserLocale = async (user) => {
    if (!user) return;
    // fetch user prefered language from db, if no language, then browser language is chosen
    let defaultLanguage = navigator.language;
    let {
      browser: { family },
    } = detect.parse(navigator.userAgent);
    if (family === "Chrome") {
      defaultLanguage = navigator.language.slice(0, 2);
    }
    await to(UserService.update(user, { language: i18n.language }));
    i18n.changeLanguage(defaultLanguage);
  };
  fetchInfoBarStatus = async () => {
    const dbRef = Firebase.database().ref(`/infoBar/`);
    dbRef.on("value", (snap) => {
      const infoBarCookie = JSON.parse(localStorage.getItem("showInfoBar"));
      if (
        !infoBarCookie ||
        snap.val().date.toString() != infoBarCookie.created
      ) {
        localStorage.setItem(
          "showInfoBar",
          JSON.stringify({
            value: snap.val().visible,
            created: snap.val().date.toString(),
          })
        );
        return this.setState({
          showInfoBar: snap.val().visible,
          infoBarMessage: snap.val().content,
        });
      }
    });
  };

  handleCloseInfoBar = () => {
    try {
      this.setState({ showInfoBar: false });
      const cookie = JSON.parse(localStorage.getItem("showInfoBar"));
      localStorage.setItem(
        "showInfoBar",
        JSON.stringify({
          ...cookie,
          value: false,
        })
      );
    } catch (e) {
      console.error("Erreur localStorage infoBarSeen", e);
    }
  };

  emptyGraphInLocalStorage = () => {
    const items = [
      "TotalEnergy",
      "ppaGraph",
      "Opex",
      "TechSummary",
      "AutoProd",
      "Van",
      "OptimizedSubP",
      "ScenarioTable",
      "LineBalanceSheet",
      "Backup",
      "FreeCashFlow",
      "EnergyBalance",
      "UnitCost",
      "CostPerMonth",
      "TotalCost",
      "MonotoneOfPower",
      "YearlyBill",
      "DailyProfile",
      "Histogram",
      "CO2",
      "LoadCurve",
      "EnergeticMix",
    ];

    items.forEach((i) => localStorage.removeItem(i));
  };

  showContactButtonIfEssentialPlan = (user) => {
    if (user && user.license) {
      if (user.license === PLANS().ESSENTIAL.name) {
        this.setState({ showContactButton: true });
      }
    }
  };

  removeChatIfEssential = (user = null) => {
    if (!user) {
      window?.HubSpotConversations?.widget.remove();
      return;
    }
    if (
      user.license === PLANS().ESSENTIAL.name ||
      user.license === PLANS().CANDI.name
    ) {
      console.log("disabling chat");
      //Disabling hubspot chat if essential
      window.HubSpotConversations.widget.remove();
    }
  };

  refreshUserLocalData = async () => {
    console.log("Checking user cookie");
    const cookie = Utils.getCookie("userDataIsUpToDate");
    if (!cookie) return await Auth.refreshUserData();
    console.log("User cookie found");
  };

  getDefaultValues = async () => {
    const ConsumerDefaultValues = await Api.getDefaultValues("CONSUMER");
    const SupplierDefaultValues = await Api.getDefaultValues("SUPPLIER");
    setDefaultValues(SiteConstants.type.CONSUMER, ConsumerDefaultValues);
    setDefaultValues(SiteConstants.type.PRODUCER, SupplierDefaultValues);
  };

  fetchUserManual = async () => {
    let url = await getFileUrlByPath(basePathUtils, nameManual);
    console.log("APPURL:", url);

    this.setState({
      manualURL: url,
    });
  };

  manageUser = async () => {
    this.removeChatIfEssential();

    // FOR C&I, we check URL and connect automatically the user
    if (window.location.hostname.includes("autoconsommation")) {
      await this.AuthToAccount();
      const loadTheme = async (theme) => {
        try {
          await this.changeTheme(theme);
        } catch {
          console.log("Cannot find theme ", theme, " falling back to default");
          await this.changeTheme("localhost");
          return;
        }
        console.log(`Theme ${theme} found`);
      };
      loadTheme(window.location.hostname);

      if (
        window.location.hash === "#/results" ||
        window.location.hash === "#/optimization"
      ) {
        this.setState({ redirectToHome: true });
      }
    }

    Firebase.auth().onAuthStateChanged(async (user) => {
      if (user && user.emailVerified) {
        const timestamp = new Date().getTime();
        if (checkPresence({ history, timestamp }) !== false) {
          const userInDB = await UserService.get(user.uid);
          if (userInDB.active) {
            await this.getDefaultValues();
            await UserService.getGridDefaultValues(userInDB);
            await UserService.addGridDefaultValues(userInDB);
            this.fetchUserManual();
            this.refreshUserLocalData();

            Auth.setUserData(userInDB);
            Auth.updateAuthenticationStore(userInDB);
            this.showContactButtonIfEssentialPlan(userInDB);
            this.getUserLanguage(user);
            // this.emptyGraphInLocalStorage();
            this.removeChatIfEssential(userInDB);
            await to(
              UserService.update(user.uid, {
                _lastConnected: dayjs().format("DD/MM/YYYY HH:mm"),
              })
            );
            this.setState({ uid: user.uid, loggedIn: true });
            if (userInDB.forcePasswordChange) {
              this.setState({
                forcePasswordChange: true,
                day: userInDB.forcePasswordChange,
              });
            }
          }
        } else {
          console.log("logged out");
          handleRedirects(history, "#/signout");
          this.setState({ loggedIn: false });
          return window.location.reload();
        }
      } else if (localStorage.getItem("navId")) {
        console.log("logged out");
        handleRedirects(history, "#/signout");
        this.setState({ loggedIn: false });
        return window.location.reload();
      }
    });
  };

  handleTimerEmail = () => {
    const { timerSendingEmail } = this.state;
    if (timerSendingEmail > 0) {
      setTimeout(() => {
        this.setState({ timerSendingEmail: timerSendingEmail - 1 });
        this.handleTimerEmail();
      }, 1000);
    } else {
      this.setState({ mailIsSent: false });
    }
  };

  handleForcePasswordChange = async () => {
    const { timerSendingEmail } = this.state;
    if (timerSendingEmail > 0) return;
    await AuthService.forceResetPassword(
      Auth.getUser().email,
      Auth.getUser().uid
    );
    await this.setState({ mailIsSent: true, timerSendingEmail: 30 });
    this.handleTimerEmail();
  };

  handleDisconnect = async () => {
    handleRedirects(history, "#/signout");
    this.setState({ loggedIn: false });
    return window.location.reload();
  };

  renderForcePasswordModal = () => {
    const { day, forcePasswordChange, mailIsSent, timerSendingEmail } =
      this.state;

    if (!day) return null;
    const resetDay = dayjs.unix(day);
    if (resetDay.isAfter(dayjs()))
      return (
        <CustomModal
          show={forcePasswordChange}
          showClose
          title={`Votre mot de passe arrive à expiration le ${resetDay.format(
            "DD/MM/YYYY"
          )}`}
          onConfirm={() => {
            this.setState({ forcePasswordChange: false });
          }}
          confirmText="Fermer"
          type="secondary"
          notCloseOnEsc
          validateLabel="Fermer"
        >
          <CustomNotification
            isCloseable={false}
            hidden={!mailIsSent}
            toggleNotification={null}
            logo="info"
          >
            Un courriel de réinitialisation de mot de passe a été expédié. Nous
            vous invitons à vérifier votre boîte de réception, y compris le
            dossier des courriers indésirables.
          </CustomNotification>

          <p style={{ padding: "0rem 2.5rem 1.25rem 1.5rem" }}>
            Votre mot de passe arrive à expiration le{" "}
            {resetDay.format("DD/MM/YYYY")}. Veuillez le changer en cliquant sur
            le lien ci-dessous.
          </p>

          {timerSendingEmail === 0 ? (
            <Button
              style={{ marginLeft: "1.5rem" }}
              color="text"
              onClick={async () => {
                this.handleForcePasswordChange();
              }}
            >
              Recevoir un lien de changement de mot de passe par mail
            </Button>
          ) : (
            <Fragment>
              <Button
                disabled
                style={{ marginLeft: "1.5rem" }}
                onClick={async () => {
                  this.handleForcePasswordChange();
                }}
              >
                <div>
                  Vous n'avez pas reçu le mail ? Réessayez dans un instant.
                  <Progress
                    style={{ height: "2px", marginTop: "4px" }}
                    max={30}
                    value={timerSendingEmail}
                    size="small"
                  />
                </div>
              </Button>
            </Fragment>
          )}
        </CustomModal>
      );
    else
      return (
        <CustomModal
          show={forcePasswordChange}
          showClose
          bodyOnly
          notCloseOnEsc
        >
          <CustomNotification
            isCloseable={false}
            hidden={!mailIsSent}
            toggleNotification={null}
            logo="info"
          >
            Un courriel de réinitialisation de mot de passe a été expédié. Nous
            vous invitons à vérifier votre boîte de réception, y compris le
            dossier des courriers indésirables.
          </CustomNotification>
          <p style={{ padding: "0rem 2.5rem 1.25rem 1.5rem" }}>
            Votre mot de passe est expiré depuis le{" "}
            {resetDay.format("DD/MM/YYYY")}. Veuillez le changer en cliquant sur
            le lien ci-dessous.
          </p>

          {timerSendingEmail === 0 ? (
            <Button
              style={{ marginLeft: "1.5rem" }}
              color="text"
              onClick={async () => {
                this.handleForcePasswordChange();
              }}
            >
              Recevoir un lien de changement de mot de passe par mail
            </Button>
          ) : (
            <Fragment>
              <Button
                disabled
                style={{ marginLeft: "1.5rem" }}
                onClick={async () => {
                  this.handleForcePasswordChange();
                }}
              >
                <div>
                  Vous n'avez pas reçu le mail ? Réessayez dans un instant.
                  <Progress
                    style={{ height: "2px", marginTop: "4px" }}
                    max={30}
                    value={timerSendingEmail}
                    size="small"
                  />
                </div>
              </Button>
            </Fragment>
          )}
          <br />
          <br />
          <Button
            color="warning"
            onClick={this.handleDisconnect}
            style={{ float: "right" }}
          >
            Me déconnecter
          </Button>
        </CustomModal>
      );
  };
  // checkPresence = async (uid) =>
  //   Firebase.database()
  //     .ref(`/users/${uid}`)
  //     .once("value")
  //     .then((snap) => {
  //       console.log({ snap });
  //       return snap.val();
  //     });

  getCurrentUser = async () => Firebase.auth().currentUser;

  // removePresenceOnLogOut = async () => {
  //   const { uid } = this.state;
  //   // this.setState({ showContactButton: false });

  //   if (uid) {
  //     const dbRef = Firebase.database().ref(`/users/${uid}`);
  //     dbRef.remove();
  //   }
  // };

  closePopUp = () => this.setState({ showEventPopUp: false });

  renderEventPopUp = () => (
    <EventPopUp show={this.state.showEventPopUp} close={this.closePopUp} />
  );

  eventPopUpWasNotSeen = () =>
    !localStorage.getItem("event_popup_seen") ||
    JSON.parse(localStorage.getItem("event_popup_seen")) === false;

  checkAppVersion = async () => {
    const dbRef = Firebase.database().ref(`/VERSION/`);
    dbRef.on("value", (snap) => {
      if (snap) {
        const REMOTE_VERSION = snap.val();
        if (
          (REMOTE_VERSION !== LOCAL_VERSION && !this.state.showUpdateButton) ||
          (REMOTE_VERSION === LOCAL_VERSION && this.state.showUpdateButton)
        )
          return this.setState({
            showUpdateButton: REMOTE_VERSION !== LOCAL_VERSION,
          });
      }
    });
  };

  //   manageZoom = () => {
  //     const zoomLevel = +(1 / window.devicePixelRatio).toFixed(1);
  //     document.body.style.zoom = zoomLevel;
  //     this.zoomLevel = zoomLevel;
  //   };

  AuthToAccount = async () => {
    let err, user;

    const authInfo = await Auth.getCandI();

    [err, user] = await to(Auth.login(authInfo));
  };

  componentDidMount = async () => {
    await this.manageUser();
    await this.checkAppVersion();

    unsetTutorialAsShown(TUTORIALS.NEW_ACC_ACI);

    // }
    // this.manageZoom();

    // if (this.eventPopUpWasNotSeen()) {
    //   setTimeout(() => {
    //     this.setState({ showEventPopUp: true });
    //   }, 2000);
    // }
    // await this.checkAppVersion();
    // this.handleBeforeUnload = this.handleBeforeUnload.bind(this);
    // window.addEventListener("beforeunload", this.handleBeforeUnload);
    window.onbeforeunload = undefined;

    this.fetchInfoBarStatus();
  };
  componentWillUnmount = () => {};

  refreshApp = () => window.location.reload();

  render() {
    // Obtenez la licence de l'utilisateur
    const userLicense = Auth.getUser()?.license;

    // Condition pour déterminer quel composant rendre
    const HomeComponent = userLicense === "C&I" ? Landing : Home;
    const { theme } = this.state;

    return (
      <Router history={history}>
        <LocaleContext.Provider
          value={{
            Translation,
            i18n,
            analytics,
            PageNames,
            Utils,
            THEME,
            zoom: this.zoomLevel,
            manualURL: this.state.manualURL,
          }}
        >
          <ThemeProvider theme={theme}>
            <Suspense fallback={<MainSkeleton />}>
              {/* {this.renderEventPopUp()} */}
              {userLicense !== "C&I" && <Header />}

              {/* {userLicense !== "C&I" && this.state.showInfoBar && (
                <InfoBar
                  content={this.state.infoBarMessage}
                  handleCloseInfoBar={this.handleCloseInfoBar}
                />
              )} */}
              {/* {this.renderEventPopUp()} */}
              <Route
                exact
                path="/consent/:userid/:siteid"
                component={() => <Consent />}
              />
              {userLicense !== "C&I" && this.state.showInfoBar && (
                <InfoBar
                  content={this.state.infoBarMessage}
                  handleCloseInfoBar={this.handleCloseInfoBar}
                />
              )}
              {process.env.REACT_APP_ENV === "production" &&
                this.state.showUpdateButton && (
                  <div className="update-app-button-container">
                    <Button color="warning" onClick={this.refreshApp}>
                      Cliquez ici pour obtenir la dernière version
                    </Button>
                  </div>
                )}

              {this.state.redirectToHome && <Redirect to="/" />}

              <Route
                exact
                path="/signin"
                render={(props) => <Signin {...props} />}
              />
              <Route
                exact
                path="/signup"
                render={(props) => <Signup {...props} />}
              />
              <Route exact path="/signout" component={Signout} />
              <PrivateRoute
                exact
                path="/"
                theme={theme}
                component={HomeComponent}
              />
              <PrivateRoute
                exact
                path="/cloudFunctionsTester"
                component={CloudFunctionsTester}
              />
              <PrivateRoute exact path="/analyze" component={Analyze} />
              <PrivateRoute
                exact
                path="/optimization"
                component={Optimization}
                theme={theme}
              />
              <PrivateRoute
                exact
                path="/results"
                theme={theme}
                component={Results}
              />
              <PrivateRoute exact path="/pilotage" component={Pilotage} />
              <PrivateRoute exact path="/me" component={MyAccount} />
              <AdminPage exact path="/admin" component={Admin}></AdminPage>
              <ManagePage exact path="/manage" component={Manage} />
              <Route exact path="/legal" component={() => <Legal />} />
              <PrivateRoute
                exact
                path="/home"
                theme={theme}
                component={HomeCandI}
              />
              <Route
                exact
                path="/dataProtection"
                component={() => <DataProtection />}
              />
              <Route exact path="/shortcutPage" component={ShortcutPage} />
              {/* <PrivateRoute exact path="/admin" component={Admin} /> */}
              {/* {this.renderUserHelp()} */}
              {this.renderForcePasswordModal()}
              <CookieConsent
                location="bottom"
                buttonText="J'accepte"
                cookieName="cguAccepted"
                cookieValue={true}
                sameSite="strict"
                expires={365}
                hideOnAccepte={true}
                disableStyles={true}
                containerClasses="container-cookie-consent"
                contentClasses="content-cookie-consent"
                buttonClasses="btn-cookie-consent"
              >
                En poursuivant votre navigation sur ce site, vous acceptez
                l'utilisation de cookies pour vous proposer des services et
                offres adaptés à vos centres d'intérêts.{" "}
                <Link
                  className="link-container"
                  style={{
                    width: "100%",
                    textAlign: "center",
                    display: "block",
                  }}
                  to={{
                    pathname: "/dataProtection",
                  }}
                >
                  Lire notre politique en matière de protection de la vie privée
                </Link>{" "}
              </CookieConsent>
              {this.state.loggedIn && this.state.showContactButton && (
                <ContactButton />
              )}
            </Suspense>
          </ThemeProvider>
        </LocaleContext.Provider>
      </Router>
    );
  }
}

export default App;
