import React, { useState, useEffect } from "react";
import "./EditorSiteForm.scss";
import { consumerPlusSiteModel } from "./ConsumerPlusComponents/Models/ConsumerPlusSiteModel";
import ApiService from "../../services/Api";
import CollectiveSitesService from "../../services/CollectiveSites";
import AuthService from "../../services/Auth";
import {
  buildAddressObject,
  canGoToStepTwo,
} from "./ConsumerPlusEditorFunctions";
import Step1 from "./Step1";
import CollectiveSites from "../../services/CollectiveSites";
import SiteService from "../../services/Site";
import UserService from "../../services/User";
import { EditorCollectiveSiteForm } from "./ConsumerPlusComponents/EditorCollectiveSiteForm";
import SiteHelper from "../../helpers/Site";
import { isSiteExtractingData } from "../../helpers/Validators";

export default function ConsumerPlusEditor(props) {
  //props
  const {
    editMode,
    siteToEdit,
    onSubmitSuccess,
    cancelEditor,
    handleRemoveEditor,
    userDefaultRates,
  } = props;
  const [collectiveSite, setCollectiveSite] = useState(consumerPlusSiteModel()); //load site model
  const [step, setStep] = useState(1); //set initial step to 1
  const [timeZoneError, setTimeZoneError] = useState("");
  const [sites, setSites] = useState([]);
  const [user, setUser] = useState();

  const [isLoading, setIsLoading] = useState(false);

  // effects

  // fetch user
  useEffect(() => {
    // fetch user and subSites
    UserService.get(AuthService.getUserId())
      .then((user) => {
        setUser(user);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    const handleCreateCollectiveSite = async () =>
      await CollectiveSites.add(collectiveSite);

    if (siteToEdit) {
      setCollectiveSite({ ...siteToEdit });
      // fetch user and subSites
      Promise.all(
        siteToEdit?.sites.map((siteId) => SiteService.getById(siteId))
      )
        .then((sites) => {
          setSites(sites.filter((site) => site));
          if (sites.includes(null)) {
            throw Error("One or more sites were not found.");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } //replace site model with siteToEdit in EditMode

    if (!siteToEdit) handleCreateCollectiveSite();
  }, [siteToEdit]);

  // on each change of sites, each second check if they have a reportURL
  useEffect(() => {
    const sitesExtractingData = sites.filter((site) =>
      isSiteExtractingData(site)
    );
    const schedules = sitesExtractingData.map((site) => {
      const currentInterval = setInterval(() => {
        SiteService.getById(site.id)
          .then((firebaseSite) => {
            // if site has a reportURL
            // update local site
            // romove interval
            if (!isSiteExtractingData(firebaseSite)) {
              setSites(
                sites.map((site) =>
                  site.id === firebaseSite.id ? firebaseSite : site
                )
              );
              clearInterval(currentInterval);
            }
          })
          .catch((error) => {
            console.error(error);
            clearInterval(currentInterval);
          });
      }, 2000);
      return currentInterval;
    });

    // on component unmout remove all pending intervals
    return () => schedules.forEach((interval) => clearInterval(interval));
  }, [sites]);

  useEffect(() => {
    if (collectiveSite.country !== "fr") {
      setCollectiveSite({
        ...collectiveSite,
        autoCo2: false,
      });
    }
  }, [collectiveSite.country]);

  //methods
  const handleChangeInput = ({ target: { name, value } }) => {
    setCollectiveSite({ ...collectiveSite, [name]: value });
  };

  const handleNextStep = () => setStep(step + 1);

  const handlePrevStep = () => setStep(!step || step < 1 ? 0 : step - 1);

  const handleCodeFromGoogle = async (place) => {
    const { country } = collectiveSite;
    setIsLoading(true);
    setTimeZoneError("");
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    let timezone = null;
    try {
      timezone = await ApiService.getTimeZone({ lat, lng });
    } catch (e) {
      setIsLoading(false);
      return setTimeZoneError(e.response.data.message);
    }

    buildAddressObject(
      setCollectiveSite,
      collectiveSite,
      place,
      country,
      timezone
    );
    setIsLoading(false);
  };

  const getMapInitialValue = () => collectiveSite.address;

  const handleCodeFromMarker = (latLng) => {
    setCollectiveSite({
      ...collectiveSite,
      addressGeocode: [latLng.lat, latLng.lng],
    });
  };

  const handleChangeCheckbox = (e) =>
    setCollectiveSite({
      ...collectiveSite,
      autoCo2: e.target.checked,
    });

  const handleCanGoStepTwo = () => canGoToStepTwo(collectiveSite);
  const { name, country, description, addressGeocode, co2rate, autoCo2 } =
    collectiveSite;

  // if onSave is executed
  // all the subSites that were added will be added to the collectiveSite's sites list
  // all the subSites that were edited, will still be edited
  // all the changes to the collectiveSite will be persisted
  // if !editMode, TMY request will be triggered
  const onSave = () => {
    setIsLoading(true);
    console.log("Saving...");

    const newCollectiveSite = {
      ...collectiveSite,
      sites: sites.map((site) => site.id),
    };
    setCollectiveSite(newCollectiveSite);
    CollectiveSitesService.update(collectiveSite.id, newCollectiveSite)
      .then(() => {
        if (!editMode) {
          // trigger TMY creation
          console.log("Fetching TMY");
          return SiteHelper.handleTMYRequests(collectiveSite);
        }
      })
      .finally(() => {
        onSubmitSuccess();
      });
  };
  // if cancelAndDeleteSubSites is executed
  // all the subSites that were added will be deleted
  // all the subSites that were edited, will still be edited
  // all the changes that were made to the collectiveSite will be ignored
  const cancelAndDeleteSubSites = async () => {
    setIsLoading(true);
    console.log("Canceling and deleting");
    // if canceling on editMode, only delete added subSites
    // is canceling on !editMode, delete all subSites (on creation collectiveSite.sites => [])
    const subSitesToDelete = sites.filter(
      (site) => !collectiveSite.sites.includes(site.id)
    );

    console.log("deleting", { subSitesToDelete });
    await SiteService.deleteAllSites(subSitesToDelete);

    if (!props.editMode) {
      console.log("deleting", { collectiveSite });
      await CollectiveSitesService.remove(collectiveSite.id);
      cancelEditor();
    } else {
      cancelEditor();
    }
  };

  switch (step) {
    case 1:
      return (
        <Step1
          site={collectiveSite}
          step={step}
          setStep={setStep}
          name={name}
          country={country}
          co2rate={co2rate}
          autoCo2={autoCo2}
          handleChangeCheckbox={handleChangeCheckbox}
          description={description}
          addressGeocode={addressGeocode}
          handleChangeInput={handleChangeInput}
          handleNextStep={handleNextStep}
          canGoToStepTwo={handleCanGoStepTwo}
          handleCodeFromGoogle={handleCodeFromGoogle}
          getMapInitialValue={getMapInitialValue}
          handleCodeFromMarker={handleCodeFromMarker}
          editMode={editMode}
          isFetchingTimeZone={isLoading}
          timeZoneError={timeZoneError}
          cancelEditor={cancelAndDeleteSubSites}
          isSubmitingSite={isLoading}
          handleRemoveEditor={() => {
            cancelAndDeleteSubSites(), handleRemoveEditor();
          }}
        />
      );
    case 2:
      return (
        <EditorCollectiveSiteForm
          editMode={editMode}
          collectiveSite={collectiveSite}
          setCollectiveSite={setCollectiveSite}
          sites={sites}
          setSites={setSites}
          user={user}
          userDefaultRates={userDefaultRates}
          handlePrevStep={handlePrevStep}
          onSubmitSuccess={onSave}
          cancelEditor={cancelAndDeleteSubSites}
          isLoading={isLoading}
        />
      );
  }
}
