import React from "react";
import { Link } from "react-router-dom";
import {
  Form,
  Button,
  Icon,
  Columns,
  Notification,
} from "react-bulma-components/full";
import to from "await-to-js";
import Axios from "axios";

import AuthConstants from "./../constants/Auth";
import Terms from "./Terms";
import "./Signup.scss";
import Auth from "../services/Auth";
import keys from "../keys";
import ApiService from "../services/Api";
import ReCAPTCHA from "react-google-recaptcha";
import PasswordVisibilityToggler from "./shared/PasswordVisibilityToggler";

// const PHONETEST = new RegExp("^((\\+|00)33|0) *[1-9]([ .-]*[0-9]{2}){4}$");
export default class Signup extends React.Component {
  initialState = () => ({
    step: 1,
    email: "",
    password: "",
    repassword: "",
    firstname: "",
    lastname: "",
    address: "",
    addressZipCode: "",
    addressCity: "",
    license: null,
    maxUsers: 1,
    optiwizeSimuForfait: 0,
    optiwizeTrForfait: 0,
    termsAccepted: false,
    isEmailUsed: false,
    isSignupLoad: false,
    signupError: null,
    signupSuccess: false,
    siteNumber: 0,
    isAdmin: false,
    isValidPassword: false,
    emailVerified: false,
    allowedNumberOfSites: 0,
    showTerms: false,
    captcha: false,
    phone: null,
    incorrectPhone: false,
    company: "",
    passwordIsHidden: true,
    confirmPasswordIsHidden: true,
  });

  constructor(props) {
    super(props);
    this.state = this.initialState();
  }

  handleChangeInput = ({ target }) => {
    const value = target.type === "checkbox" ? target.checked : target.value;

    this.setState({ [target.name]: value });
  };
  handleBlurEmailInput = async ({ target }) => {
    const { email } = this.state;
    const value = email.trim();
    let err = null;

    if (value === "") {
      return;
    }
    [err] = await to(Auth.isEmailUsed(value));
    if (err)
      return this.setState({
        signupError: "Cette adresse email est déjà utilisée.",
        isEmailUsed: true,
      });
    return this.setState({ signupError: null, isEmailUsed: false });
  };

  handleSignup = async (e) => {
    if (e) e.preventDefault();
    this.setState({ isSignupLoad: true, signupError: null });
    const [err, uid] = await to(
      Auth.createAccountWithEmailAndPassword(this.state)
    );
    if (err) {
      switch (err.code) {
        case "auth/email-already-in-use":
          this.setState({
            isSignupLoad: false,
            signupError: "Cette adresse mail est déjà utilisée.",
            step: 1,
          });
          break;
        case "auth/operation-not-allowed":
          this.setState({
            isSignupLoad: false,
            signupError:
              "Impossible de créer votre compte par le biais de cette méthode.",
            step: 1,
          });
          break;
        case "auth/invalid-email":
          this.setState({
            isSignupLoad: false,
            signupError: "L'email fourni n'est pas valide.",
            step: 1,
          });
          break;
        case "auth/weak-password":
          this.setState({
            isSignupLoad: false,
            signupError: "Votre mot de passe est trop faible.",
            step: 1,
          });
          break;
        default:
          this.setState({
            isSignupLoad: false,
            signupError: `Une erreur inconnue est survenue. (${err.message})`,
            step: 1,
          });
          break;
      }
    }
    this.setState({ isSignupLoad: false, signupSuccess: true, uid });
    return Promise.resolve(true);
  };

  handlePurchase = async (forfeit, numberOfSites) => {
    let err,
      userId = null;

    [err, userId] = await to(this.handleSignup());
    if (err) return;
    Axios.post(AuthConstants.SIGNUP_SEND_QUOTATION_API_URL, {
      forfeit,
      userId,
      numberOfSites,
    });
  };
  handleNextStep = async () => {
    const { captcha } = this.state;
    let err = null;
    if (!captcha) return;
    [err] = await to(this.handleSignup());
    if (!err) return this.setState({ step: this.state.step + 1 });
    else return;
  };
  handlePrevStep = () => this.setState({ step: this.state.step - 1 });
  handleChangeOptiwizeSimuConfig = (optiwizeSimuForfait) =>
    this.setState({ optiwizeSimuForfait });
  handleChangeOptiwizeTrConfig = (optiwizeTrForfait) =>
    this.setState({ optiwizeTrForfait });

  isPasswordsSame = () => this.state.password === this.state.repassword;

  componentDidUpdate(prevProps, prevState) {
    if (prevState.password !== this.state.password) {
      const { email, password } = this.state;
      const isLenbthValid = password.length >= 6;
      const isLowercaseValid = /[a-z]/.test(password);
      const isUppercaseValid = /[A-Z]/.test(password);
      const isDigitValid = /[0-9]/.test(password);
      const isSpecialCharValid =
        /.*[!@#=$%^&*()_+{}\[\]:;<>,.?\/\\~`|-].*/.test(password);

      this.setState({
        isLenbthValid,
        isLowercaseValid,
        isUppercaseValid,
        isDigitValid,
        isSpecialCharValid,
        isValidPassword:
          isLowercaseValid &&
          isUppercaseValid &&
          isDigitValid &&
          isSpecialCharValid,
      });
    }
  }

  handleCaptcha = (v) => this.setState({ captcha: v ? true : false });
  canGoToStepTwo = () => {
    const {
      email,
      password,
      repassword,
      firstname,
      lastname,
      company,
      address,
      addressCity,
      addressZipCode,
      termsAccepted,
      isEmailUsed,
      captcha,
      incorrectPhone,
    } = this.state;

    if (
      email.length !== 0 &&
      password.length >= 6 &&
      repassword.length !== 0 &&
      firstname.length !== 0 &&
      lastname.length !== 0 &&
      company.length !== 0 &&
      address.length !== 0 &&
      addressCity.length !== 0 &&
      addressZipCode.length !== 0 &&
      this.isPasswordsSame() &&
      termsAccepted &&
      !isEmailUsed &&
      captcha &&
      !incorrectPhone
    )
      return true;
    return false;
  };

  handleChangePhone = ({ target: { value } }) => {
    this.setState({ phone: value });
  };
  renderStepOne = () => {
    const {
      email,
      password,
      repassword,
      firstname,
      lastname,
      address,
      addressCity,
      addressZipCode,
      termsAccepted,
      showTerms,
      phone,
      isSignupLoad,
      company,
    } = this.state;

    return (
      <div className="signup-wrapper container is-centered">
        <div className="columns">
          <div className="column is-half is-offset-one-quarter">
            <div className="box">
              <div className="title is-4 has-text-centered">
                Créez votre compte
              </div>
              {this.renderErrorNotification()}
              <Columns>
                <Columns.Column size={12}>
                  <div className="title is-6">Coordonnées</div>
                </Columns.Column>
                <Columns.Column size={6}>
                  <Form.Field>
                    <Form.Control>
                      <Form.Input
                        name="firstname"
                        onChange={this.handleChangeInput}
                        value={firstname}
                        placeholder="Prénom*"
                      />
                    </Form.Control>
                  </Form.Field>
                </Columns.Column>
                <Columns.Column size={6}>
                  <Form.Field>
                    <Form.Control>
                      <Form.Input
                        name="lastname"
                        onChange={this.handleChangeInput}
                        value={lastname}
                        placeholder="Nom*"
                      />
                    </Form.Control>
                  </Form.Field>
                </Columns.Column>
                <Columns.Column size={12}>
                  <Form.Field>
                    <Form.Control>
                      <Form.Input
                        name="address"
                        onChange={this.handleChangeInput}
                        value={address}
                        placeholder="Adresse postale*"
                      />
                    </Form.Control>
                  </Form.Field>
                </Columns.Column>
                <Columns.Column size={6}>
                  <Form.Field>
                    <Form.Control>
                      <Form.Input
                        name="addressZipCode"
                        onChange={this.handleChangeInput}
                        value={addressZipCode}
                        placeholder="Code Postal*"
                      />
                    </Form.Control>
                  </Form.Field>
                </Columns.Column>
                <Columns.Column size={6}>
                  <Form.Field>
                    <Form.Control>
                      <Form.Input
                        name="addressCity"
                        onChange={this.handleChangeInput}
                        value={addressCity}
                        placeholder="Ville*"
                      />
                    </Form.Control>
                  </Form.Field>
                </Columns.Column>
                <Columns.Column size={6}>
                  <Form.Field>
                    <Form.Control>
                      <Form.Input
                        name="company"
                        onChange={this.handleChangeInput}
                        value={company}
                        placeholder="Entreprise*"
                      />
                    </Form.Control>
                  </Form.Field>
                </Columns.Column>

                <Columns.Column size={6}>
                  <Form.Field>
                    <Form.Control>
                      <Form.Input
                        name="phone"
                        onChange={this.handleChangePhone}
                        value={phone}
                        placeholder="Téléphone"
                      />
                    </Form.Control>
                  </Form.Field>
                </Columns.Column>

                <Columns.Column size={12}>
                  <div className="title is-6">Identifiants de connexion</div>
                </Columns.Column>
                <Columns.Column size={12}>
                  <Form.Field>
                    <Form.Control iconLeft>
                      <Form.Input
                        name="email"
                        onChange={this.handleChangeInput}
                        value={email}
                        type="email"
                        placeholder="Adresse e-mail*"
                        onBlur={this.handleBlurEmailInput}
                      />
                      <Icon align="left">
                        <i className="fa fa-envelope" />
                      </Icon>
                    </Form.Control>
                  </Form.Field>
                </Columns.Column>
                <Columns.Column size={12}>
                  <Form.Field>
                    <Form.Control iconLeft>
                      <Form.Input
                        name="password"
                        onChange={this.handleChangeInput}
                        value={password}
                        type={this.state.passwordIsHidden ? "password" : "text"}
                        placeholder="Mot de passe*"
                      />
                      <Icon align="left">
                        <i className="fa fa-lock" />
                      </Icon>
                      <PasswordVisibilityToggler
                        isHidden={this.state.passwordIsHidden}
                        onClick={() =>
                          this.setState({
                            passwordIsHidden: !this.state.passwordIsHidden,
                          })
                        }
                      />
                    </Form.Control>
                    {password.length > 0 && (
                      <Form.Help onSubmit={this.handleSignup}>
                        <div className="text-password">
                          Votre mot de passe doit comporter au moins :
                        </div>
                        <div className="list-password">
                          <ul>
                            <li>
                              {this.state.isLenbthValid ? (
                                <i class="fa fa-check"></i>
                              ) : (
                                <i class="fa fa-close"></i>
                              )}
                              6 caractères
                            </li>
                            <li>
                              {this.state.isLowercaseValid ? (
                                <i class="fa fa-check"></i>
                              ) : (
                                <i class="fa fa-close"></i>
                              )}
                              Une lettre minuscule
                            </li>
                            <li>
                              {this.state.isUppercaseValid ? (
                                <i class="fa fa-check"></i>
                              ) : (
                                <i class="fa fa-close"></i>
                              )}
                              Une lettre majuscule
                            </li>
                            <li>
                              {this.state.isDigitValid ? (
                                <i class="fa fa-check"></i>
                              ) : (
                                <i class="fa fa-close"></i>
                              )}
                              Un chiffre
                            </li>

                            <li>
                              {this.state.isSpecialCharValid ? (
                                <i class="fa fa-check"></i>
                              ) : (
                                <i class="fa fa-close"></i>
                              )}
                              Un caractère spécial
                            </li>
                          </ul>
                        </div>
                      </Form.Help>
                    )}
                  </Form.Field>
                </Columns.Column>
                <Columns.Column size={12}>
                  <Form.Field>
                    <Form.Control iconLeft>
                      <Form.Input
                        name="repassword"
                        onChange={this.handleChangeInput}
                        value={repassword}
                        type={
                          this.state.confirmPasswordIsHidden
                            ? "password"
                            : "text"
                        }
                        placeholder="Répéter le mot de passe*"
                      />
                      <Icon align="left">
                        <i className="fa fa-lock" />
                      </Icon>
                      <PasswordVisibilityToggler
                        isHidden={this.state.confirmPasswordIsHidden}
                        onClick={() =>
                          this.setState({
                            confirmPasswordIsHidden:
                              !this.state.confirmPasswordIsHidden,
                          })
                        }
                      />
                    </Form.Control>
                    {!this.isPasswordsSame() && repassword.length > 0 && (
                      <Form.Help>
                        <div className="list-repassword">
                          Les mots de passe ne correspondent pas.
                        </div>
                      </Form.Help>
                    )}
                  </Form.Field>
                </Columns.Column>
                <Columns.Column>
                  <div className="has-text-justified">
                    <div className="check">
                      <Form.Checkbox
                        name="termsAccepted"
                        onChange={this.handleChangeInput}
                        checked={termsAccepted}
                      />
                    </div>
                    <span>
                      J'accepte les{" "}
                      <a
                        href="#terms"
                        onClick={(e) => {
                          e.preventDefault();
                          if (showTerms)
                            this.setState({ showTerms: !showTerms });
                          else
                            setTimeout(
                              () =>
                                document
                                  .getElementById("terms")
                                  .scrollIntoView({ behavior: "smooth" }),
                              100
                            );
                          this.setState({ showTerms: !showTerms });
                        }}
                      >
                        conditions générales d'utilisation
                      </a>
                    </span>
                  </div>
                </Columns.Column>
              </Columns>
              <ReCAPTCHA sitekey={keys.CAPTCHA} onChange={this.handleCaptcha} />

              <Form.Field>
                <Form.Control className="has-text-right">
                  <Button
                    color="info"
                    type="primary"
                    onClick={this.handleNextStep}
                    disabled={
                      !this.canGoToStepTwo() || !this.state.isValidPassword
                    }
                    loading={isSignupLoad}
                  >
                    Suivant
                  </Button>
                </Form.Control>
              </Form.Field>
            </div>
          </div>
        </div>
        {showTerms && <Terms />}
      </div>
    );
  };

  renderErrorNotification = () => {
    const { signupError } = this.state;

    if (signupError == null) return;
    return <Notification color="danger">{signupError}</Notification>;
  };
  sendSignUpEmail = async (params) => {
    params.env = process.env.REACT_APP_ENV;

    const token = await ApiService.getToken();

    await Axios.post(AuthConstants.URL_ADMIN_SIGNUP, params, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `${token}`,
      },
    });
  };
  renderSuccessSignUp = () => {
    const {
      uid,
      email,
      firstname,
      lastname,
      address,
      addressCity,
      addressZipCode,
      company,
      phone,
    } = this.state;

    this.sendSignUpEmail({
      uid,
      email,
      firstname,
      lastname,
      address,
      addressCity,
      addressZipCode,
      company,
      phone,
    });

    return (
      <div className="signup-wrapper container is-centered">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="box">
              <div className="title is-4 has-text-centered">
                <div className="step-subtitle subtitle is-uppercase">
                  Félicitations
                </div>
                Votre compte a été créé avec succès !
              </div>
              <div className="has-text-centered">
                Nous vous invitons à valider votre inscription à l'aide du mail
                que nous venons de vous envoyer
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { step } = this.state;
    switch (step) {
      case 1:
        return this.renderStepOne();
      case 2:
        return this.renderSuccessSignUp();
      default:
        return <div>Etape inconnue...</div>;
    }
  }
}
