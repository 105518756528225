import { db } from "../fire";

import DatabaseConstants from "../constants/Database";
import { uuid } from "uuidv4";

import dayjs from "dayjs";
import to from "await-to-js";

class ConsentService {
  constructor() {
    this.storageBaseReport = "/reports";
  }

  getSiteConsent = async (siteId) => {
    let err,
      data = null;
    [err, data] = await to(
      db
        .collection(DatabaseConstants.COLLECTION_CONSENT)
        .where("siteId", "==", siteId)
        .get()
    );
    if (err) return Promise.reject(err);

    return Promise.resolve(data.docs.map((doc) => doc.data()));
  };

  add = async (data) => {
    const refConsent = db
      .collection(DatabaseConstants.COLLECTION_CONSENT)
      .doc(uuid());
    data._createdAt = dayjs().format("DD/MM/YYYY HH:mm");
    return refConsent.set(data);
  };
}

export default new ConsentService();
